(function() {
    angular
        .module('fca.brandApp')
        .directive('fcaFleetRequestInformationForm', fcaFleetRequestInformationForm);

    function fcaFleetRequestInformationForm() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                analyticscategory: '@',
                analyticsid: '@',
                modalId: '@'
            },
            controllerAs: 'fcaFleetRequestInformationForm',
            controller: fcaFleetRequestInformationForm,
        };

        function fcaFleetRequestInformationForm($scope, $rootScope, $element, gtmAnalytics, $location) {
            'ngInject';

            const $ctrl = this;

            $scope.nameValue = '';
            $scope.companyNameValue = '';
            $scope.emailValue = '';
            $scope.phoneNumberValue = '';
            $scope.fleetSizeValue = '';
            $scope.titleValue = undefined;
            $scope.acceptConditionsCheckbox = '';

            $ctrl.formSended = false;

            $ctrl.phoneRegex = '^\\(?([2-9][0-9]{2})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
            $ctrl.emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

            $ctrl.$onInit = () => {
                angular.element(document).ready(() => {
                    $ctrl.requestInfoBtn = $('.navigation-group-item-request-info > a');
                    $ctrl.requestInfoBtnFooter = $('.F_FCTA .F_FCTA-cta-informations');
                    $ctrl.requestInfoBtnMobile = $('.F_MCI-informations-cta');
                    $ctrl.setRequestInfoBtn();
                });
            };

            $ctrl.setRequestInfoBtn = () => {
                if ($ctrl.requestInfoBtn) {
                    $ctrl.requestInfoBtn.on('click', event => {
                        event.preventDefault();
                        $ctrl.openModalForm();
                    });
                }

                if ($ctrl.requestInfoBtnFooter) {
                    $ctrl.requestInfoBtnFooter.on('click', event => {
                        event.preventDefault();
                        $ctrl.openModalForm();
                    });
                }

                if ($ctrl.requestInfoBtnMobile) {
                    $ctrl.requestInfoBtnMobile.on('click', event => {
                        event.preventDefault();
                        $ctrl.openModalForm();
                    });
                }
            };

            $ctrl.openModalForm = event => {
                $rootScope.$broadcast('fcaModal:open', { modalId: $ctrl.modalId });

                $scope.$$postDigest(() => {
                    if ($ctrl.formSended) {
                        $ctrl.submitConfirmation();
                    }
                });
            };

            $ctrl.scrollTopError = event => {
                let scroll = () => {
                    let topError = document.querySelector('#formTopError');
                    topError.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
                };
                setTimeout(scroll, 100);
            };

            $ctrl.submit = (evt) => {
				evt.preventDefault();
				evt.stopPropagation();
                $ctrl.submitForm($element);
            };

            $ctrl.submitConfirmation = () => {
                document.querySelector('.C_MW-container').classList.add('form-sent-modal-styles');
            };

            $ctrl.submitForm = ($element) => {
                const allInputs = $('.F_form--request-information input');

                var requestInformation = {};
                for (let input of allInputs) {
                    // for checkbox value, we only add them if they are checked
                    if (input.id === 'formsMs') {
                        if (input.checked == true) {
                            requestInformation[input.name] = input.value;
                        }
                    } else if (input.id == 'formsMr') {
                        if (input.checked == true) {
                            requestInformation[input.name] = input.value;
                        }
                    } else if (input.id == 'formsOther') {
                        if (input.checked == true) {
                            requestInformation[input.name] = input.value;
                        }
                    } else if (input.id == 'minivan') {
                        if (input.checked == true) {
                            requestInformation[input.id] = input.value;
                        }
                    } else if (input.id == 'commercialVan') {
                        if (input.checked == true) {
                            requestInformation[input.id] = input.value;
                        }
                    } else if (input.id == 'truck') {
                        if (input.checked == true) {
                            requestInformation[input.id] = input.value;
                        }
                    } else if (input.id == 'suv') {
                        if (input.checked == true) {
                            requestInformation[input.id] = input.value;
                        }
                    } else if (input.id == 'inputEmail') {
                        if (input.value) {
                            requestInformation['email'] = input.value;
                        }
                    } else {
                        requestInformation[input.id] = input.value;
                    }
                }

                const questionInput = $('#question');
                if (questionInput.val()) {
                    requestInformation[questionInput.attr('id')] = questionInput.val();
                }

                $("#btn-submit").prop("disabled", true);

                $.ajax({
                    type: "post",
                    contentType: 'application/json; charset=utf-8',
                    url: "/api/fleet/submit/" + window.FCA_SITES_CONFIG.language,
                    data: JSON.stringify(requestInformation),
                    cache: false,
                    success: function (data) {
                        var json = data;
                        const errorTag = $('#errors');
                        errorTag.html(json);

                        $ctrl.formSended = true;
                        $scope.$apply();
                        $ctrl.submitConfirmation();
                        $ctrl.sendGAevent('succeed');
                    },
                    error: function (e) {
                        var json = e.responseText;
                        const errorTag = $('#errors');
                        errorTag.html(json);

                        console.log("ERROR on request information form post : ", e);
                        $("#btn-search").prop("disabled", false);

						$ctrl.sendGAevent('failed');
					}
                });
            };

            $ctrl.sendGAevent = (label) => {
                let GAcategory = $ctrl.analyticscategory;
                let GAlabel = $ctrl.analyticsid + '-' + label;

                gtmAnalytics.trackEvent('event', {
                    category: GAcategory,
                    label: GAlabel
                })
            };
        }
    }
})();
