(function() {
	let vehicleRecommender = $('.js-vehiclerecommender');
	let $pageSearch = vehicleRecommender.find('.js-vehiclerecommender-search');
	let $pageResults = vehicleRecommender.find('.js-vehiclerecommender-results');
	// let $loadScreen = vehicleRecommender.find('.js-vehiclerecommender-loading');

	let btnToResultsFiltered = $pageSearch.find('.js-vehiclerecommender-toresultsfiltered');
	let btnToResults = $pageSearch.find('.js-vehiclerecommender-toresults');
	let vehicleAmount = $pageSearch.find('.js-vehiclerecommender-vehicleamount');
	let incrementalFilters = $pageSearch.find('.js-vehiclerecommender-incrementalfilter');
	let booleanFilters = $pageSearch.find('.js-vehiclerecommender-booleanfilter');
	let radioFilters = $pageSearch.find('.js-vehiclerecommender-radiofilter');

	let btnToSearch = $pageResults.find('.js-vehiclerecommender-tosearch');
	let $nameplateHolder = $pageResults.find('.js-vehiclerecommander-nameplateholder');
	let $nameplateResultsHolder = $pageResults.find('.js-vehiclerecommander-resultholder');
	let $nameplates = $nameplateHolder.find('.js-vehiclerecommender-nameplate');
	let resultsAmount = $pageResults.find('.js_vehicle_recommender_results-count');

	let currentBrand = $('body').attr('brand');

	let pageData = {view: 'search'};
	let searchData = {};

	let firstTimeInitial = true;
	let initialSearchResults = {};
	let searchResults = {};

	let btnShowMoreNameplate = $pageResults.find('.js-vehiclerecommender-btnshowmore');
	let nbrNameplateVisible = btnShowMoreNameplate.data('show-initial');
	let showMoreIncrement = btnShowMoreNameplate.data('show-increment');
	let $tabbarBtn = $pageResults.find('[data-tabbarbtn]');
	let $tabBarAllBtn = $pageResults.find('.js-vehiclerecommender-tabbarallbtn');

	let distanceFilter = $('.js-vehiclerecommender-distancecontrol');

	let vehicleRecommenderDebounceDelay = 1000; // 1 second
	let vehicleRecommenderTimeout;

	// ---------------------------------------------
	// ---------------------------------------------
	// INITIALIZE VEHICLE RECOMMENDER
	// ---------------------------------------------
	// ---------------------------------------------

	function init() {
		if(vehicleRecommender.length > 0) {
			initStickyCTA();
			getInitialLocation();
			requestCarAmounts(true);
			initializeFromUrl();
			// Initialize UI buttons
			setupTransitionBtn();
			setupShowMoreBtn();
			// Initialize search filters and controls
			setupDistanceFilter();
			setupSpecialTabbar();
			setupLocationFilter();
			setupIncrementalFilters();
			setupBooleanFilters();
			setupRadioFilters();
		}
	}

	function initStickyCTA() {
		$('.js-VR-cta-buffer').height($('.js-VR-cta-holder').outerHeight());
		$(window).on('resize', function() {
			$('.js-VR-cta-buffer').height($('.js-VR-cta-holder').outerHeight());
		});
		new Waypoint.Sticky({
			element: $('.js-VR-cta-holder')[0],
			direction: 'up',
			offset: 'bottom-in-view'
		});
	}

	// should not be HARD coded
	function getInitialLocation() {
		searchData.latitude = 45.5088400;
		searchData.longitude = -73.5878100;
		searchData.distance = 50;
		searchData.budget = incrementalFilters.data("initial-value");
	}

	function requestCarAmounts(isInitial) {
		let searchArg = {};
		const isValidData =
			['latitude', 'longitude', 'distance', 'budget'].every(
				key => searchData[key] !== undefined && !isNaN(searchData[key])
			);
		if (isValidData) {
			if (isInitial) {
				searchArg.latitude = searchData.latitude;
				searchArg.longitude = searchData.longitude;
				searchArg.distance = searchData.distance;
				searchArg.budget = searchData.budget;
			} else {
				searchArg = searchData;
			}
			carAmountsApiRequest(searchArg, isInitial);
		}
	}

	function carAmountsApiRequest(searchArg, isInitial) {
		$.ajax({
			type: 'POST',
			url: '/data/vehiclerecommender?schema=v4', // schema=v4 is for jellies handling
			data: JSON.stringify(searchArg),
			contentType: 'application/json; charset=utf-8',
			crossDomain: 'true',
			dataType: 'json',
			success: function(data) {
				if (data.totalNbVehicle === 0) {
					$('.js-vehiclerecommender-ctawithresults').hide();
					$('.js-vehiclerecommender-ctawithoutresults').show();
				} else {
					$('.js-vehiclerecommender-ctawithresults').show();
					$('.js-vehiclerecommender-ctawithoutresults').hide();
				}

				$('.js-VR-cta-buffer').height($('.js-VR-cta-holder').outerHeight());
				Waypoint.refreshAll();

				if (isInitial === true) {
					initialSearchResults = data;
					if (firstTimeInitial === true) {
						vehicleAmount.text(data.totalNbVehicle);
						resultsAmount.text(data.totalNbVehicle);
						firstTimeInitial = false;
					}
				} else {
					searchResults = data;
					vehicleAmount.text(data.totalNbVehicle);
					resultsAmount.text(data.totalNbVehicle);
					updateJellies();
					if (pageData.view === 'results') {
						showUnfilteredNameplates();
					}
					if (pageData.view === 'results-filtered') {
						showFilteredNameplates();
					}
					// $loadScreen.removeClass('js-active');
				}
			},
			failure: function(errMsg) {
				console.log(errMsg);
			}
		});

	}

	function debounceRequestCar(isInitial) {
		window.clearTimeout(vehicleRecommenderTimeout);
		vehicleRecommenderTimeout = window.setTimeout(function() {
			if(isInitial) {
				requestCarAmounts(true);
				requestCarAmounts(false);
			} else {
				requestCarAmounts(false);
			}
		}, vehicleRecommenderDebounceDelay);
	}

	function initializeFromUrl() {
		let cleanUrl = window.location.hash.substring(1).replace(/\:/g, '[').replace(/\;/g, ']');
		let objUrl = $.deparam(cleanUrl, true);

		pageData = objUrl.pageData || pageData;
		searchData = objUrl.searchData || searchData;

		getInitialLocation();
		initRecoveredSearch();
		animateToCurrentPage();
		requestCarAmounts(false);
	}

	function initRecoveredSearch() {
		// For each key retrieved in searchData, activate buttons and values in the page
		Object.keys(searchData).forEach(function(key) {
			let type = $('[data-search-key="'+key+'"]').data('search-type');
			if (type === 'radio') {
				setRecoveredRadioInfo(key, searchData[key]);
			}
			if (type === 'boolean') {
				setRecoveredBooleanInfo(key, searchData[key]);
			}
			if (type === 'increment') {
				setRecoveredIncrementInfo(key, searchData[key]);
			}
		});
		setRecoveredDistanceInfo();
	}

	function updateUrl() {
		let urlData = {
			pageData: pageData,
			searchData: searchData
		};
		window.location.hash = decodeURIComponent($.param(urlData)).replace(/\[/g, ':').replace(/\]/g, ';');
	}

	// ---------------------------------------------
	// ---------------------------------------------
	// PAGE CHANGE SETUP
	// ---------------------------------------------
	// ---------------------------------------------

	function setupTransitionBtn() {
		btnToResults.on('click', function() {
			pageData.view = 'results';
			showUnfilteredNameplates();
			animateToCurrentPage();
			dataLayer = window.dataLayer || [];
			dataLayer.push({'seeresults': 'click'});
		});
		btnToResultsFiltered.on('click', function() {
			pageData.view = 'results-filtered';
			showFilteredNameplates();
			animateToCurrentPage();
			dataLayer = window.dataLayer || [];
			dataLayer.push({'choosefromlineup': 'click'});
		});
		btnToSearch.on('click', function() {
			pageData.view = 'search';
			animateToCurrentPage();
		});
	}

	function animateToCurrentPage() {
		updateUrl();
		$('html,body').scrollTop(0);
		if (pageData.view === 'results' || pageData.view === 'results-filtered') {
			// $loadScreen.addClass('js-active');
			$pageSearch.hide();
			$('#zones_recommender-header_blocks_mini-nav').addClass('ishidden');
			setTimeout(() => {
				window.focusFunctions.enableDisableFocusOnElements($('#zones_recommender-header_blocks_mini-nav')[0], false);
			}, 10);
			$pageResults.show();
			toggleActiveTabbarBtn();
		} else {
			$pageSearch.show();
			$('#zones_recommender-header_blocks_mini-nav').removeClass('ishidden');
			setTimeout(() => {
				window.focusFunctions.enableDisableFocusOnElements($('#zones_recommender-header_blocks_mini-nav')[0], true);
			}, 10);
			$pageResults.hide();
			$('.js-VR-cta-buffer').height($('.js-VR-cta-holder').outerHeight());
			Waypoint.refreshAll();
		}
	}

	// ---------------------------------------------
	// ---------------------------------------------
	// FILTERS SETUP
	// ---------------------------------------------
	// ---------------------------------------------

	function setupSpecialTabbar() {
		$tabbarBtn.on('click', function() {
			/* eslint-disable no-invalid-this */
			$tabbarBtn.removeClass('active');
			$(this).addClass('active');
			let resultsNameplates = $nameplateResultsHolder.children();
			resultsNameplates.addClass('js-tabbar-hidden');
			let currentVehicleType = $(this).data('tabbarbtn');
			switch (currentVehicleType) {
			case 'cars':
				resultsNameplates.filter('[data-vehicle-type="cars"]').removeClass('js-tabbar-hidden');
				break;
			case 'suvs':
				resultsNameplates.filter('[data-vehicle-type="suvs"]').removeClass('js-tabbar-hidden');
				break;
			case 'trucks':
				resultsNameplates.filter('[data-vehicle-type="trucks"]').removeClass('js-tabbar-hidden');
				break;
			case 'mini-vans':
				resultsNameplates.filter('[data-vehicle-type="mini-vans"]').removeClass('js-tabbar-hidden');
				break;
			case 'all':
			default:
				resultsNameplates.removeClass('js-tabbar-hidden');
				break;
			}
			displayOnlyAFewNameplates();
		});
	}

	function setupLocationFilter() {
		vehicleRecommender.on('vehicle-recommender-event:location-change', function(e) {
			searchData.latitude = parseFloat(e.lat);
			searchData.longitude = parseFloat(e.long);
			updateUrl();
			debounceRequestCar(true);
		});
	}

	function setupDistanceFilter() {
		/* eslint-disable no-invalid-this */
		let distanceControls = distanceFilter.find('a');
		let distanceLabel = distanceFilter.find('.js-vehiclerecommender-distancelabel');
		distanceControls.each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();
				searchData.distance = $(this).data('search-distance');
				distanceLabel.text($(this).data('search-distance'));
				updateUrl();
				debounceRequestCar(true);
			});
		});
	}

	function setRecoveredDistanceInfo() {
		let distanceControls = distanceFilter.find('a');
		let distanceLabel = distanceFilter.find('.js-vehiclerecommender-distancelabel');
		if(searchData.distance===undefined || isNaN(searchData.distance)) {
			searchData.distance = distanceControls.first().data('search-distance');
		}
		distanceLabel.text(searchData.distance);
	}

	function setupIncrementalFilters() {
		/* eslint-disable no-invalid-this */
		incrementalFilters.each(function() {
			let searchKey = $(this).data('search-key');
			let $valHolder = $(this).find('.js-vehiclerecommender-increment-value');
			let incrementMin = $(this).data('increment-min');
			let incrementMax = $(this).data('increment-max');
			let incrementVal = $(this).data('increment-amount');
			searchData[searchKey] = parseInt($valHolder.text());

			// Setup the plus and minus button to update the value
			$(this).find('.js-vehiclerecommender-incrementalcontrol').on('click', function() {
				let currentVal = parseInt($valHolder.text());
				let direction = $(this).data('increment-direction');
				let futurValue = currentVal+(incrementVal*direction);
				// If new increment is within accepted parameters, update the value
				if( futurValue <= incrementMax && futurValue >= incrementMin ) {
					currentVal = futurValue;
					$valHolder.text(currentVal);
					searchData[searchKey] = currentVal;
					updateUrl();
					debounceRequestCar(false);
				}

				let trackingValue = searchKey + (direction < 0 ? "-decrease" : "-increase") + "-select-" + currentVal;

				dataLayer = window.dataLayer || [];
				dataLayer.push({trackingValue});
			});
		});
	};

	function setRecoveredIncrementInfo(key, data) {
		let $incrementFilter = $('.js-vehiclerecommender-incrementalfilter[data-search-key="'+key+'"]');
		let incrementMin = $incrementFilter.data('increment-min');
		let incrementMax = $incrementFilter.data('increment-max');
		let incrementVal = $incrementFilter.data('increment-amount');
		let $valHolder = $incrementFilter.find('.js-vehiclerecommender-increment-value');
		let recoveredValue = data;
		if((recoveredValue%incrementVal) !== 0) {
			recoveredValue = Math.floor(recoveredValue/incrementVal);
		}
		if(recoveredValue < incrementMin) {
			recoveredValue = incrementMin;
		}
		if(recoveredValue > incrementMax) {
			recoveredValue = incrementMax;
		}
		searchData[key] = recoveredValue;
		$valHolder.text(recoveredValue);
	}

	function setupBooleanFilters() {
		/* eslint-disable no-invalid-this */
		booleanFilters.each(function() {
			let searchKey = $(this).data('search-key');
			// Setup the controls to active/non-active
			$(this).find('.js-vehiclerecommender-booleancontrol').on('click', function() {
				// Handle state change of clicked control
				if($(this).hasClass('active-filter')) {
					$(this).removeClass('active-filter');
					searchData[searchKey] = searchData[searchKey].filter((val)=>{
						return val !== $(this).data('search-key');
					});
					// If no option is selected remove the key in data object
					if (searchData[searchKey].length === 0) {
						delete searchData[searchKey];
					}
				} else {
					$(this).addClass('active-filter');
					searchData[searchKey] = searchData[searchKey] || [];
					searchData[searchKey].push($(this).data('search-key'));
				}
				updateUrl();
				debounceRequestCar(false);

				let value = $(this)[0].dataset.searchKey;
				let trackingValue = searchKey.toLowerCase() + "-" + value.toLowerCase() + "-" + ($(this).hasClass('active-filter') ? 'seleceted' : 'unselected');
				dataLayer = window.dataLayer || [];
				dataLayer.push({trackingValue});
			});


		});
	}

	function setRecoveredBooleanInfo(key, data) {
		let $booleanFilter = $('.js-vehiclerecommender-booleanfilter[data-search-key="'+key+'"]');
		data.forEach(function(value) {
			let $button = $booleanFilter.find('[data-search-key="'+value+'"]');
			if ($button.length > 0) {
				$button.addClass('active-filter');
			} else {
				searchData[key] = searchData[key].filter((val)=>{
					return val !== value;
				});
				// If no option is selected remove the key in data object
				if (searchData[key].length === 0) {
					delete searchData[key];
				}
			}
		});
	}

	function setupRadioFilters() {
		/* eslint-disable no-invalid-this */
		radioFilters.each(function() {
			let searchKey = $(this).data('search-key');

			// Setup the controls to active/non-active
			$(this).find('.js-vehiclerecommender-radiocontrol').on('click', function() {
				// Deselect any active controls
				$(this).siblings().removeClass('active-filter');

				// Handle state change of clicked control
				if($(this).hasClass('active-filter')) {
					$(this).removeClass('active-filter');
					// If no option is selected remove the key in data object
					delete searchData[searchKey];
				} else {
					$(this).addClass('active-filter');
					searchData[searchKey] = $(this).data('search-key');
				}
				updateUrl();
				debounceRequestCar(false);

				let value = $(this)[0].dataset.searchKey;
				let trackingValue = searchKey.toLowerCase() + "-" + value.toLowerCase() + "-" + ($(this).hasClass('active-filter') ? 'seleceted' : 'unselected');
				dataLayer = window.dataLayer || [];
				dataLayer.push({trackingValue});
			});
		});
	}

	function setRecoveredRadioInfo(key, data) {
		let $radioFilter = $('.js-vehiclerecommender-radiofilter[data-search-key="'+key+'"]');
		let $button = $radioFilter.find('[data-search-key="'+data+'"]');
		if ($button.length > 0) {
			$button.addClass('active-filter');
		} else {
			delete searchData[key];
		}
	}

	// ---------------------------------------------
	// ---------------------------------------------
	// RESULTS SETUP
	// ---------------------------------------------
	// ---------------------------------------------

	function showUnfilteredNameplates() {
		let sortedResults = getBrandResultsFirst();
		resultsAmount.text(initialSearchResults.totalNbVehicle);
		$nameplateResultsHolder.empty();
		sortedResults.forEach(function(resultResult, index) {
			setupNameplate(resultResult, false, index);
		});
		resetResultsHolder();
	}

	function showFilteredNameplates() {
		let sortedResults = getSortedResults();
		resultsAmount.text(searchResults.totalNbVehicle);
		$nameplateResultsHolder.empty();
		sortedResults.forEach(function(result, index) {
			setupNameplate(result, true, index);
		});
		resetResultsHolder();
	}

	function resetResultsHolder() {
		nbrNameplateVisible = btnShowMoreNameplate.data('show-initial');
		$('.js-VR-dropdownButton').parent().removeClass('js-open');
		displayOnlyAFewNameplates();
		setupNameplatesEvents();
		$tabBarAllBtn.trigger('click');
		toggleActiveTabbarBtn();
	}

	function toggleActiveTabbarBtn() {
		let nbrCars = $nameplateResultsHolder.find('[data-vehicle-type="cars"]').length;
		let nbrSuvs = $nameplateResultsHolder.find('[data-vehicle-type="suvs"]').length;
		let nbrTrucks = $nameplateResultsHolder.find('[data-vehicle-type="trucks"]').length;
		let nbrMiniVans = $nameplateResultsHolder.find('[data-vehicle-type="mini-vans"]').length;
		$('.js-VR-tabbar').show();
		$('[data-tabbarbtn]').show();
		if (nbrCars === 0) $('[data-tabbarbtn="cars"]').hide();
		if (nbrSuvs === 0) $('[data-tabbarbtn="suvs"]').hide();
		if (nbrTrucks === 0) $('[data-tabbarbtn="trucks"]').hide();
		if (nbrMiniVans === 0) $('[data-tabbarbtn="mini-vans"]').hide();
		$('[data-tabbarbtn]:visible').length >= 3 ? $('.js-VR-tabbar').show() : $('.js-VR-tabbar').hide();
	}

	function displayOnlyAFewNameplates() {
		let resultsNameplates = $nameplateResultsHolder.children().not('.js-tabbar-hidden');
		resultsNameplates.addClass('hidden');
		resultsNameplates.each(function(index) {
			if ((index+1) <= nbrNameplateVisible) {
				$(this).removeClass('hidden');
			}
		});
		if(nbrNameplateVisible >= resultsNameplates.length) {
			btnShowMoreNameplate.hide();
		} else {
			btnShowMoreNameplate.show();
		}
	}

	function setupShowMoreBtn() {
		btnShowMoreNameplate.on('click', function() {
			nbrNameplateVisible += showMoreIncrement;
			displayOnlyAFewNameplates();
		});
	}

	function updateJellies() {
		searchResults.nameplates.forEach(function(resultat, index) {
			let nameplate = $nameplates.filter('[data-nameplate-code='+resultat.code+']');
			let nameplateImg = nameplate.find('.vehicle_recommender_results--nameplate-vehicle');
			let filteredImage = nameplateImg.find('.nameplate-filtered-jelly');
			let originalImage = nameplateImg.find('.jelly img');
			if(resultat.jelly !== null && resultat.jelly !== undefined && originalImage.length !== 0) {
				let currentJellySrc = nameplateImg.find('meta').attr('content');
				let currentJellyObject = _getCleanJellyUrl(currentJellySrc);
				// GENERATE ARRAY FOR RESPONSIVE IMAGE -> NOT WORKING RIGHT NOW
				// let jellies = _getResponsiveJellyData(currentJellyObject.baseNoSize, resultat.jelly);
				// filteredImage.attr('bh-src-responsive', jellies);
				if (currentJellyObject != null && currentJellyObject != undefined) {
					let newJelly = currentJellyObject.base + '/' + resultat.jelly;
					filteredImage.attr('src', newJelly);
					filteredImage.show();
					originalImage.hide();
				} else {
					filteredImage.hide();
					originalImage.show();
				}
			} else {
				filteredImage.hide();
				originalImage.show();
			}
		});
	}

	function setupNameplate(resultat, shouldShowMatch, order) {
		let nameplate = $nameplates.filter('[data-nameplate-code='+resultat.code+']');
		nameplate.find('.js-vehiclerecommender-nameplatenbr').text(resultat.nbVehicle);
		if (shouldShowMatch) {
			nameplate.attr('data-match-type', resultat.perfectMatch);
			if(resultat.perfectMatch) {
				let textDropdown = '<ul>';
				resultat.perfectMatchTrims.forEach(function(trim) {
					textDropdown += `<li sortingScore='${trim.sortingScore}'>`;
					textDropdown += trim.trimDescription[window.BRANDS_GA.pagelanguage];
					textDropdown += '</li>';
				});
				textDropdown += '</ul>';
				nameplate.attr('sortingScore', resultat.bestScoreTrim.sortingScore);
				nameplate.find('.js-VR-matchingText').html(textDropdown);
				nameplate.find('.js-VR-matchingTrims').show();
				nameplate.find('.js-VR-reasonsDropdown').hide();
			} else {
				nameplate.removeAttr('sortingScore');
				let texts = $('.js-VR-partialMatchTexts').html();
				nameplate.find('.js-VR-reasonsText').html(texts);
				resultat.partialMatchProperties.forEach(function(reason) {
					let reasonGroup = nameplate.find('[data-reason-group="'+reason.type+'"]');
					reasonGroup.show();
					reasonGroup.find('[data-reason-key="'+reason.property+'"]').show();
				});
				nameplate.find('.js-VR-reasonsDropdown').show();
				nameplate.find('.js-VR-matchingTrims').hide();
			}
		} else {
			nameplate.attr('data-match-type', 'none');
		}
		if(resultat.nbVehicle >= 1) {
			$nameplateResultsHolder.append(nameplate);
		}
	}

	function getBrandResultsFirst() {
		let brandNameplates = [];
		let otherNameplates = [];
		let allNameplates = [];
		initialSearchResults.nameplates.forEach(function(nameplate) {
			if(nameplate.brandCode === currentBrand) {
				brandNameplates.push(nameplate);
			} else {
				otherNameplates.push(nameplate);
			}
		});
		return allNameplates.concat(brandNameplates, otherNameplates);
	}

	function getSortedResults() {
		let perfectMatch = [];
		let partialMatch = [];
		let sortedResults = [];
		searchResults.nameplates.forEach(function(nameplate) {
			if(nameplate.perfectMatch === true) {
				perfectMatch.push(nameplate);
			} else {
				partialMatch.push(nameplate);
			}
		});
		return sortedResults.concat(perfectMatch, partialMatch);
	}

	function setupNameplatesEvents() {
		$nameplateResultsHolder.children().each(function() {
			$(this).find('.js-VR-dropdownButton').on('click', function() {
				$(this).parent().toggleClass('js-open');
			});
		});
	}

	// ---------------------------------------------
	// ---------------------------------------------
	// HELPER FUNCTIONS
	// ---------------------------------------------
	// ---------------------------------------------

	/* eslint-disable */

	function _getCleanJellyUrl(dirtyUrl) {
		let regexSplit = new RegExp('\/(?:.(?!\/))+$');
		let objectUrl = null;

		if (dirtyUrl != null && dirtyUrl != undefined) {
			let baseUrl = dirtyUrl.replace(regexSplit, '');
			let baseNoSize = baseUrl.replace(regexSplit,'');
			let fileUrl = dirtyUrl.replace(baseUrl, '').substring(1);
			objectUrl = {
				base : baseUrl,
				baseNoSize : baseNoSize,
				file : fileUrl,
			};
		}
		return objectUrl;
	}

	// function _getResponsiveJellyData(base, img) {
	// 	let data = [
	// 	    [
	// 	        '(min-width: 1px)',
	// 	        base + '/290x185/' + img
	// 	    ],
	// 	    [
	// 	        '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1px) and (min--moz-device-pixel-ratio: 2),(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1px) and (min-device-pixel-ratio: 2),(min-width: 1px) and (min-resolution: 192dpi),(min-width: 1px) and (min-resolution: 2dppx)',
	// 	        base + '/580x370/' + img
	// 	    ],
	// 	    [
	// 	        '(min-width: 768px)',
	// 	        base + '/400x255/' + img
	// 	    ],
	// 	    [
	// 	        '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 768px) and (min--moz-device-pixel-ratio: 2),(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 768px) and (min-device-pixel-ratio: 2),(min-width: 768px) and (min-resolution: 192dpi),(min-width: 768px) and (min-resolution: 2dppx)',
	// 	        base + '/800x510/' + img
	// 	    ],
	// 	    [
	// 	        '(min-width: 1025px)',
	// 	        base + '/400x255/' + img
	// 	        ],
	// 	    [
	// 	        '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1025px) and (min-device-pixel-ratio: 2),(min-width: 1025px) and (min-resolution: 192dpi),(min-width: 1025px) and (min-resolution: 2dppx)',
	// 	        base + '/800x510/' + img
	// 	    ]
	// 	];
	// 	return JSON.stringify(data);
	// }

	// jQuery deparam is an extraction of the deparam method from Ben Alman's jQuery BBQ https://github.com/chrissrogers/jquery-deparam
	(function(h){h.deparam=function(i,j){var d={},k={"true":!0,"false":!1,"null":null};h.each(i.replace(/\+/g," ").split("&"),function(i,l){var m;var a=l.split("="),c=decodeURIComponent(a[0]),g=d,f=0,b=c.split("]["),e=b.length-1;/\[/.test(b[0])&&/\]$/.test(b[e])?(b[e]=b[e].replace(/\]$/,""),b=b.shift().split("[").concat(b),e=b.length-1):e=0;if(2===a.length)if(a=decodeURIComponent(a[1]),j&&(a=a&&!isNaN(a)?+a:"undefined"===a?void 0:void 0!==k[a]?k[a]:a),e)for(;f<=e;f++)c=""===b[f]?g.length:b[f],m=g[c]=
	f<e?g[c]||(b[f+1]&&isNaN(b[f+1])?{}:[]):a,g=m;else h.isArray(d[c])?d[c].push(a):d[c]=void 0!==d[c]?[d[c],a]:a;else c&&(d[c]=j?void 0:"")});return d}})(jQuery);
	/* eslint-enable */

	init();
})();
