/* eslint-disable indent */
(function() {
    'use strict';
    angular
        .module('fca.dealerDetailsInventory', [])
        .component('fcaDealerDetailsInventory', {
            controller: DealerDetailsInventoryCtrl,
            controllerAs: '$ctrl',
            templateUrl: '/dealer-details-inventory/dealer-details-inventory.html',
            bindings: {
                currentBrandName: "@",
                dealerCode: "@",
                showAvailableModels: '<',
                showBottomCtaBar: '<',
                sniDealerUrl: '@',
                irisWidth: '<'
            }
        });

    function DealerDetailsInventoryCtrl($http, $rootScope, fcaSniTrimService, irisResponsive) {
        'ngInject';
        const $ctrl = this;
        const baseIrisJellyUrl = FCA_SITES_CONFIG.irisJellyBaseUrl + "/mediaserver/iris?client=FCAUS&market=U";
        $ctrl.brandCode = window.FCA_SITES_CONFIG.name;
        $ctrl.language = window.FCA_SITES_CONFIG.language;
        $ctrl.jelliesUrl = window.FCA_SITES_CONFIG.jelliesUrl;
        $ctrl.siteGroupCode = window.FCA_SITES_CONFIG.siteGroupCode;

        $ctrl.dataLoaded = false;


        let dealerDetailsBlock = $('.C_DD-container');

        $ctrl.$onInit = () => {
            $ctrl.irisJellyDimensions = irisResponsive.calcDimensions(
                { ratio: 1920 / 1080, maxWidth: $ctrl.irisWidth}
            );

            if (dealerDetailsBlock.length > 0) {
                $ctrl.callApi();
            }
        };

        $ctrl.callApi = () => {
            const apiUrl = (
                `/data/inventories/${$ctrl.brandCode}` +
                `/dealer/${$ctrl.dealerCode}`
            );

            $http.get(apiUrl).then($ctrl.apiResponse, $ctrl.apiError);
        };

        $ctrl.apiResponse = (response) => {
            $ctrl.nameplates = response.data;

            if ($ctrl.nameplates.length) {
                $ctrl.dataLoaded = true;
                $ctrl.nameplates.forEach(nameplate => {
                    /*
                    This condition makes sure that we are not in the Vehicle inventory detail page,
                    so that there are not more api calls than needed.
                    */
                    if(nameplate.brandCode !== 'alfaromeo' || $('body').find('#zones_body_blocks_overview').length) {
                        return;
                    }
                    nameplate.modelYears.forEach(modelYear => {
                        fcaSniTrimService.getSniTrimData(
                            nameplate.brandCode, nameplate.nameplateCode,
                            modelYear.year, modelYear.modelYearId).then(value => {
                            /**
                             * Use the first trim of the first trim group.
                             * If that trim does not have a primary colour code, use the first trim of the second trim group.
                             */
                            const trim = value.data.trimGroups[0].trims[0].primaryColourCode
                                    ? value.data.trimGroups[0].trims[0]
                                    : value.data.trimGroups[1]
                                        ? value.data.trimGroups[1].trims[0]
                                        : value.data.trimGroups[0].trims[0];
                                const mfgCode = trim.mfgCode;
                                const packageCode = trim.packages[0].code;
                                const paintCode = trim.primaryColourCode;
                                const vehicle = `${modelYear.year}_${mfgCode.substr(0,2)}`;
                                const sa =`${mfgCode},${packageCode},${packageCode.substr(0, 1)}D${packageCode.substr(2, 1)}`;
                                modelYear.irisJellyUrl =
                                    `${baseIrisJellyUrl}&brand=Y`+
                                    `&vehicle=${vehicle}&paint=${paintCode}&sa=${sa}`+
                                    `&width=${Math.round($ctrl.irisJellyDimensions.width)}`+
                                    `&height=${Math.round($ctrl.irisJellyDimensions.height)}`+
                                    `&bkgnd=white&pov=fronthero`;
                                modelYear.trimDataLoaded = true;
                        }, value => {
                                console.error('api call for trims return 404, return value:', value);
                            }).catch(e => {
                        });
                    })
                })
            }

            $ctrl.activeNameplateYear = $ctrl.nameplates.map(nameplate => (
                nameplate.modelYears[0].modelYearId
            ));
        };

        $ctrl.apiError = (err) => console.error(err);

        $ctrl.getInventoryPath = (nameplateCode) => {
            const basePath = window.FCA_SITES_CONFIG.urls.
                sniDealerDetails[$ctrl.language];

            return `${basePath}${$ctrl.dealerCode}/` +
                   `${$ctrl.brandCode}/${nameplateCode}?sort=price&sortOrder=asc`;
        };

        $ctrl.jellyUrlForModelYear = (modelYear) => (
            `${$ctrl.jelliesUrl}${modelYear.year}/` +
            `400x255/${modelYear.jellyName}`
        );

        $ctrl.switchNameplateYear = (nameplateIndex, modelYearId) => {
            $ctrl.activeNameplateYear[nameplateIndex] = modelYearId
        };

        $ctrl.generateAltTag = (pModelYear, pBrandName, pNamepateName) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName;
            if(window.FCA_SITES_CONFIG.language == 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear;
            }
            return altText;
        }
    }
})();
