(function() {
    'use strict';

    angular
        .module('fca.userLocation')
        .service('userLocation', UserLocation);

    /**
     * This is responsible for the province/region dropdown.
     * It is also used to determine the user's position in a lot of templates.
     *
     * It expects a region to be composed of a province AND a region.
     * For example: QC,quebec
     *
     */
    function UserLocation($rootScope, $window, $cookies, $http, $q, $timeout, storageService, fcaGeolocator) {
        'ngInject';

        let {locationApiPath} = $window.FCA_SITES_CONFIG.urls;
        let {expires, location: cookieName} = $window.FCA_SITES_CONFIG.cookies;

        let location;

        let locationLaunched = false;

        /**
         *
         * This should only be called when we don't have an actual location (when switching region with the pull down).
         * 1. set region/province in offers-region cookie
         * 2. Broadcast event which triggers the update of several components listening to the broadcast event
         * 3. Broadcast sets default city for that region/province in brd-location cookie
         *
         */
        this.changeLocation = (loc, forceLocation, useExactLocation = false) => {
            if (angular.isObject(loc)) {
                // Set offers-region cookie
                if (forceLocation) {
                    this.forceLocation(loc);
                } else {
                    this.setLocation(loc);
                }

                // TODO: remove this, this component shouldn't have to test about the existence of a dealer id
                let hashParameters = getHashParameters();
                let hasHashParameter = hashParameters.dealerId && hashParameters.dealerId !== 'null';
                let dealerId = storageService.sessionStorageGetItem('dealerId');

                if ((!dealerId && !hasHashParameter) || forceLocation) {
                    // Update location
                    location = angular.copy(loc);
                    // Setting updated location only for brand
                    let province = loc['region'].split(',')[0];
                    // determine the default postal code for the province
                    let postalCode = useExactLocation ? loc.postalCode :this.getDefaultPostalCodeForProvince(province);

                    // Get a geocode object for that postal code, once done broadcast it
                    fcaGeolocator.geocodeWithPostalCode(postalCode)
                        .then((location) => { // eslint-disable-line max-len
                            // TODO : FCATM-1564
                            window.PubSubManager.publish('location:locationChanged', location);
                        });
                }
            }

            return location;
        };

        // Return current stored location or null
        this.getLocation = () => {
            if (!location) {
                return this.initLocation();
            }

            return location;
        };

        this.getLocationLabel = () => {
            let loc = this.testEndOfGeolocation();
            let label = getLabel(loc.split(',')[0]);

            return label;
        };

        this.getLocationRegion = () => {
            let loc = this.testEndOfGeolocation();
            let region = loc.split(',')[1];

            return region;
        };

        this.getLocationProvince = () => {
            let loc = this.testEndOfGeolocation();
            let province = loc.split(',')[0];

            return province;
        };

        this.testEndOfGeolocation = () => {
            let loc = location;
            if (typeof loc != 'string') {
                loc = getDefaultLocation()['region'];
            }

            return loc;
        };
// TODO FCATM-1651
        this.initLocation = () => {
            let cookie = $cookies.get(cookieName);
            // check if already have a location
            if (cookie !== undefined) {
                if (!location) {
                    location = angular.copy(cookie);
                }
                broadcastLocation(location);
            } else {
				$http.get(window.FCA_SITES_CONFIG
					.getCurrentPositionUrl).then((response) => {
					this.setLocation(response.data);
				}).catch(() => {
					let loc = getDefaultLocation();
					this.setLocation(loc);
				});

                // try to geolocate user
                location = $q.defer();

                navigator.geolocation.getCurrentPosition((position) => {
                    location = $http.get(locationApiPath, {
                        method: 'GET',
                        params: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                    }).then((response) => {
                        buildFromGeolocation(response.data.province);
            });
            });
                return location.promise;
            }

            return location;
        };

        this.getDefaultPostalCodeForProvince = (province) => {
            /* eslint-disable complexity */

            let defaultPostalCode = 'M4W1A1';
            switch(province) {
                case 'AB':
                    defaultPostalCode = 'T5B0B7';
                    break;
                case 'BC':
                    defaultPostalCode = 'V5K1A1';
                    break;
                case 'MB':
                    defaultPostalCode = 'R2X1L3';
                    break;
                case 'NB':
                    defaultPostalCode = 'E3A0A1';
                    break;
                case 'NL':
                    defaultPostalCode = 'A1C1A1';
                    break;
                case 'NT':
                    defaultPostalCode = 'X1A2R2';
                    break;
                case 'NS':
                    defaultPostalCode = 'B3H1M3';
                    break;
                case 'NU':
                    defaultPostalCode = 'X0A0H0';
                    break;
                case 'ON':
                    defaultPostalCode = 'M4W1A1';
                    break;
                case 'PE':
                    defaultPostalCode = 'C1A2S4';
                    break;
                case 'QC':
                    defaultPostalCode = 'H2Z1H2';
                    break;
                case 'SK':
                    defaultPostalCode = 'S7J1E3';
                    break;
                case 'YT':
                    defaultPostalCode = 'Y1A4M9';
                    break;
            }
            return defaultPostalCode;
        };

        // make setLocation public
        this.setLocation = (loc, noBroadcast) => {
            // TODO: remove the dealer id tests
            let hashParameters = getHashParameters();
            let hasHashParameter = hashParameters.dealerId && hashParameters.dealerId !== 'null';
            let dealerId = storageService.sessionStorageGetItem('dealerId');
            if (!dealerId && !hasHashParameter) {
                setLocation(loc, noBroadcast);
            }
        };

        this.forceLocation = (loc) => {
            setLocation(loc);
        };

        this.verifyUserOutsideCanada = (loc) => {
            let outsideCanada = false;

            console.debug('User Location:', location, typeof(location));

            if (locationLaunched === false) {
                if (typeof (loc) === 'object') {
                    outsideCanada = loc.outsideCanada;
                    if (outsideCanada === null) {
                        outsideCanada = false;
                    }
                } else if (typeof (loc) === 'string') {
                    outsideCanada = false;
                }

                locationLaunched = true;
                $timeout(() => {
                    emitOutsideCanada(outsideCanada);
                })
                return outsideCanada;
            } else {
                emitOutsideCanada(outsideCanada);
                return outsideCanada;
            }
        };

        function emitOutsideCanada(outsideCanadaValue) {
            $rootScope.$emit('outside-canada-determined', outsideCanadaValue);
        }

        function setLocation(loc, noBroadcast) {
            let value = loc['region'];
            // if we're actually working with a location, we need to create the region with province,region format
            if (value.indexOf(',') == -1 && loc.province) {
                value = loc.province + "," + getRegionByProvince(loc.province);
            }

            $cookies.put(cookieName, value, {
                expires: expires,
                path: '/'
            });

            location = value;
            if (!noBroadcast) broadcastLocation(location);
        }

        function buildFromGeolocation(province) {
            let region = window.FCA_SITES_CONFIG.location[province]['region'];
            let locationObj = {'region': `${province},${region}`};

            setLocation(locationObj);
        }

        function getLabel(provinceCode) {
            let label = window.FCA_SITES_CONFIG.location[provinceCode]['desc'];

            return label;
        }

        function broadcastLocation(location) {
            let province = location.split(',')[0];
            let region = location.split(',')[1];
            let isValid = validateRequest(province);

            if (isValid) {
                $rootScope.$emit('regionalOffers.locationChange', location, province, region, getLabel(province)); // eslint-disable-line max-len
            } else {
                setLocation(getDefaultLocation());
            }
        }

        function validateRequest(province) {
            try {
                window.FCA_SITES_CONFIG.location[province]['desc'];
            } catch(error) {
                return false;
            }

            return true;
        }

        function getDefaultLocation() {
            // only need to return region property
            return {
                region: 'ON,ontario'
            };
        }

        // TODO: put regions per province directly in js
        function getRegionByProvince(province) {
            return window.FCA_SITES_CONFIG.location[province]['region'];
        }
    }
})();
