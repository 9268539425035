(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.provider('fcaGeolocator', fcaGeolocatorProvider);

	/**
	 * @ngdoc service
	 * @name fca.geolocator.provider:geolocatorProvider
	 * @description [TODO]
	 * @example
	 * <pre>
	 * [TODO]
	 * </pre>
	 */
	function fcaGeolocatorProvider(externalConfigLoaderProvider) {
		'ngInject';

        this.config = externalConfigLoaderProvider.loadConfig('FCA_SITES_CONFIG');

        /**
         * @ngdoc property
         * @name defaults
         * @propertyOf fca.geolocator.provider:geolocatorProvider
         * @description Defaults configuration for geocoding services
         * @type {Object}
         */
        this.defaults = {
            apiUrl: this.config.getConfig('apiUrl'),
            autoCompleteServiceUrl: this.config.getConfig('autoCompleteServiceUrl'),
            geoCodingServiceUrl: this.config.getConfig('geoCodingServiceUrl'),
            getCurrentPositionUrl: this.config.getConfig('getCurrentPositionUrl')
        };

		/**
		 * @ngdoc method
		 * @name use
		 * @propertyOf fca.geolocator.provider:geolocatorProvider
		 * @description Setter for configurations properties
		 * @param {Object} options Configuration object
		 */
		this.use = (options) => {
			this.defaults = ng.extend({}, this.defaults, options);
			return this;
		};

		// Return service
		this.$get = Service;
	}

	function Service(
		$window,
		$log,
		$q,
		$http,
		$location,
		cookieLocation,
		$filter,
		gtmAnalytics) {
		'ngInject';

		// Return service instance
		return new GeocoderServiceFactory(
			this.defaults,
			$window,
			$log,
			$q,
			$http,
			$location,
			cookieLocation,
			$filter,
			gtmAnalytics);

		/**
		 * @ngdoc service
		 * @name fca.geolocator.service:fcaGeolocator
		 * @requires $window
		 * @requires $log
		 * @requires $q
		 * @requires $http
		 * @description [TODO]
		 * @example
		 * <pre>[TODO]</pre>
		 */
		function GeocoderServiceFactory(
			factoryCfgs,
			$window,
			$log,
			$q,
			$http,
			$location,
			cookieLocation,
			$filter,
			gtmAnalytics) {
			'ngInject';

			const LOCATION_BASE_FIELD = 'locationBaseOn';
			const LOCATION_BASE = ['browser', 'userInput'];
			const _distance = $filter('distance');

			return {
				getConfig: getConfig,
				getApiUrl: getApiUrl,
				isSupported: isSupported,
				detectCurrentPosition: detectCurrentPosition,
				reverseGeocode: reverseGeocode,
				geocodeWithPostalCode: geocodeWithPostalCode,
				getCurrentLocation: getCurrentLocation,
				getDefaultLocation: getDefaultLocation,
				setProvinceCookie: setProvinceCookie,
				// TODO: either properly document the need for having both events or remove one
				getLocationChangedEvent: function() {
					return 'EVENT.geolocation.locationChanged';
				},
				getLocationUpdatedEvent: function() {
					return 'EVENT.geolocation.locationUpdated';
				},
				getLocationBaseField: function() {
					return LOCATION_BASE_FIELD;
				},
				getLocationBase: function() {
					return LOCATION_BASE;
				},
				getLocationBasedOnBrowser: function() {
					return LOCATION_BASE[0];
				},
				getLocationBasedOnUserInput: function() {
					return LOCATION_BASE[1];
				},
				isLocationBaseOnClientSide: function(location) {
					let isClientSide = false;
					if (ng.isObject(location) && location.hasOwnProperty(LOCATION_BASE_FIELD)) {
						let locationBaseOn = location[LOCATION_BASE_FIELD];
						isClientSide = (LOCATION_BASE.indexOf(locationBaseOn) !== -1);
					}

					return isClientSide;
				},
				isInRadius: function(location, reference, radius) {
					let distance = _distance(location, reference);
					let inRadius = true;

					if (distance > radius) {
						inRadius = false;
					}

					return inRadius;
				}
			};

			/**
			 * @ngdoc method
			 * @name getConfig
			 * @methodOf fca.geolocator.service:fcaGeolocator
			 * @return {Object} Configuration service object
			 */
			function getConfig() {
				return factoryCfgs;
			}

			/**
			 * @ngdoc method
			 * @name getApiUrl
			 * @methodOf fca.geolocator.service:fcaGeolocator
			 * @param {String} token Property name to retrieve in configuration
			 * @return {String} Url for geocoding or autocomplete service
			 */
			function getApiUrl(token) {
				// let uri = (factoryCfgs.apiUrl || '').replace(/\/$/, '');
				let url = `${factoryCfgs[token]}`;
				return url;
			}

			/**
			 * @ngdoc method
			 * @name isSupported
			 * @methodOf fca.geolocator.service:fcaGeolocator
			 * @return {Boolean} Return true if the geolocation is supported by the browser
			 */
			function isSupported() {
				let supported = false;

				if ($window.navigator.geolocation) {
					supported = true;
				}

				return supported;
			}

			/**
			 * @ngdoc method
			 * @name detectCurrentPosition
			 * @methodOf fca.geolocator.service:fcaGeolocator
			 * @return {Object} Return angular promise and position object (latitude, longitude) for reverse geocoding
			 */
			function detectCurrentPosition() {
				let defer = $q.defer();

				if (!isSupported()) {
					$log.warn('fcaGolocator service exception: geolocation is not supported !');
					/* Fail promise */
					defer.reject(null);
				}

				/* Get location from the browser's geo-location */
				$window.navigator.geolocation.getCurrentPosition((position) => {
					browserLocationReceived(position);
					defer.resolve(position.coords);
				}, () => {
					defer.resolve(null);
				});

				console.debug('detectCurrentPosition', defer.promise);

				return defer.promise;
			}

			function browserLocationReceived(position) {
				gtmAnalytics.trackEvent('event', {
					category: `Location Preference`,
					label: `Confirm Location`
				});
			}

			function getDefaultLocation() {
				return {
					latitude: "43.7068",
					longitude: "-79.3984",
					name: "TORONTO",
					outsideCanada: false,
					postalCode: "M4R 1K8",
					province: "ON",
					reference: false,
					region: "ontario"
				};
			}

			/**
			 * @ngdoc method
			 * @name getCurrentLocation
			 * @methodOf fca.geolocator.service:geolocator
			 * @description Retrieve location server information detection
			 * @return {Object} Return angular promise and location object on resolved
			 */
			function getCurrentLocation() {
				let url = getApiUrl('getCurrentPositionUrl');
				return $http.get(url).then((r) => {
					let locationData = r.data;
					console.debug('getCurrentLocation:', r.data);
					return locationData;
				});
			}

			/**
			 * @ngdoc method
			 * @name reverseGeocode
			 * @methodOf fca.geolocator.service:fcaGeolocator
			 * @description Retrieve location city based on latitude/longitude information
			 * @return {Object} Return angular promise and city object on resolved
			 */
			function reverseGeocode(position) {
				let url = getApiUrl('geoCodingServiceUrl');
				let lat = position.latitude;
				let lng = position.longitude;

				return $http.get(`${url}?latitude=${lat}&longitude=${lng}`).then((r) => {
					return r.data;
				});
			}

			function geocodeWithPostalCode(postalCode) {
				let url = getApiUrl('geoCodingServiceUrl');
				let dealerFromStorage = sessionStorage.getItem('dealer-lock');
				if(dealerFromStorage != null && dealerFromStorage != '') {
					postalCode = JSON.parse(dealerFromStorage).zipPostal;
				}
				let params = `postalCode=${postalCode}`;
				return $http.get(`${url}?${params}`).then((r) => {
					return r.data;
				});
			}
			function setProvinceCookie(provinceCode) {
				const apiUrl = '/api/cookie/set-province-cookie';
				const params = {
					provinceCode: provinceCode // Use the passed provinceCode parameter
				};
				window.FcaCookieChecker.addFunctionToCallbackList(() => {
					setProvinceCookie(provinceCode)
				}, 'brd-province', true);
				return $http.post(apiUrl, params);
			}
		}
	}
})(angular);
