/* eslint-disable indent */
(function () {
    angular
    .module('fca.buildAndPrice.director')
    .component('buildAndPriceDirector', {
        controller: BuildAndPriceDirector,
        controllerAs: '$ctrl',
        template: '<ng-include src="getTemplateUrl()"/>',
        bindings: {
            baseUrl: '@',
            nameplateName: '@',
            trimName: '@',
            trimNameEn: '@',
            trimNameFr: '@',
            trimImage: '@',
            baseSniBuildUrl: '@',
            disclaimerNetAmount: '@',
            disclaimerFinance: '@',
            disclaimerLease: '@',
            missingWheelUrl: '@',
			visibleSni: '@',
            eshopUrl: '@',
            customOrderActivated: '@',
			hasCustomOrderSni: '@',
            engineIcon: '@',
            transmissionIcon: '@',
        }
    });

    function BuildAndPriceDirector($scope, $rootScope, $location, $filter, $http, trimService, configService, userLocation,
        fcaGeolocator, $timeout, $translate, userAccountService, functionExecutionControl, fcaUserIdPersistentService) {
        'ngInject';

        let $ctrl = this;

        // Package code is unknown at boot time,
        // A trim and package will initially be sent by the package-selector
        $ctrl.selectedTrim = {};
        $ctrl.selectedPackage = {};
        $ctrl.trimDictionary = {};
        $ctrl.netAmount = "";
        $ctrl.totalDiscount = "";
        $ctrl.leasePerWeek = "";
        $ctrl.leaseInterestRate = "";
        $ctrl.leaseDuration = "";
        $ctrl.financePerWeek = "";
        $ctrl.financeInterestRate = "";
        $ctrl.financeDuration = "";
        $ctrl.location = "";
        $ctrl.locationLabel = "";
        $ctrl.selectedOptions = "";
        $ctrl.queryParams = "";
        $ctrl.contactDealerUrl = "custom-build-dealer";
        $ctrl.downPayment = "";
        $ctrl.tradeIn = "";
        $ctrl.oweOnTrade = "";
        $ctrl.optionsAmount = "";
        $ctrl.isPostLoading = false;
		$ctrl.savedVehicle = false;

        $ctrl.step = "packages";
        $ctrl.onStepSummary = false;
        $ctrl.language = FCA_SITES_CONFIG.language;

        $ctrl.trimGroupSelectorUrl = $location.url().substr(0, $location.url().lastIndexOf('/'));

        $scope.getTemplateUrl = function() {
            let path = window.location.pathname;

            if (path.indexOf('custom-order') > 0) {
                return '/build-and-price/director/director-custom-order.html';
            } else {
                return '/build-and-price/director/director.html';
            }
        };

        $ctrl.$onInit = () => {

            // Manage Affiliate mode
            storeAffiliateId();
            $ctrl.affiliateMode = isAffiliateMode();

			storeCustomOrderParam();

            let hash = window.location.hash;

            $ctrl.netAmount = "";
            $ctrl.isModelStep = hash.indexOf('models') > 0;

            if ($ctrl.missingWheelUrl) {
                configService.setMissingWheelUrl($ctrl.missingWheelUrl);
            }
            $ctrl.initLocation();
            $ctrl.queryParams = addQueryParametersToUrl($ctrl.queryParams);
            $ctrl.hashParameters = getHashParameters();

            if ($ctrl.hashParameters != {}) {
                $ctrl.activeTab = $ctrl.hashParameters.activeTab || 'cash';
                $ctrl.restoreSession = $ctrl.hashParameters.restoreSession;
            }

            if ($ctrl.campaignCode && $ctrl.campaignCode !== 'null'
                && $ctrl.campaignCode !== '') {
                window.FcaCookieChecker.addSessionStorage('campaignCode', $ctrl.campaignCode);
            } else {
                sessionStorage.removeItem('campaignCode');
            }


            $ctrl.urlSuffix = postProcessUrl($ctrl.hashParameters, $ctrl.activeTab);

            $ctrl.paymentType = $ctrl.hashParameters.activeTab ? $ctrl.hashParameters.activeTab : 'cash';
            configService.setPaymentType($ctrl.paymentType);
            $ctrl.inIframeMode = $location.search()['view-name'] === 'headless_renderer';
            $ctrl.displaySNIMode();
            $ctrl.displayAffiliateMode();

            if ($ctrl.isCustomOrder) {
                let urlParams = new URLSearchParams(window.location.search);
                let dealerCode = urlParams.get('dealerCode');

                $('.C_SB-holder').removeClass('stuck');

                $(window).scroll(function(e) {
                    var $el = $('.C_BP-custom-order-nav-bottom');
                    var isPositionFixed = ($el.css('position') == 'fixed');

                    if ($(this).scrollTop() > 250 && !isPositionFixed) {
                        $el.addClass('stuck');
                    }
                    if ($(this).scrollTop() < 30 && isPositionFixed) {
                        $el.removeClass('stuck');
                    }
                });

                if ($ctrl.isModelStep) {
                    //assign general starting price/finance/lease
                    $ctrl.netAmount = $ctrl.customOrderStartingPrice;

                    if ($ctrl.customOrderFinancePayment) {
                        $ctrl.financePerWeek = $ctrl.customOrderFinancePayment;
                        $ctrl.frequency = $ctrl.customOrderFinanceFrequency;
                        $ctrl.financeAprRate = $ctrl.customOrderFinanceApr;
                        $ctrl.financeEirRate = $ctrl.customOrderFinanceEir;
                        $ctrl.financeDuration =$ctrl.customOrderFinanceTerm;
                    }

                    if ($ctrl.customOrderLeasePayment) {
                        $ctrl.leasePerWeek = $ctrl.customOrderLeasePayment;
                        $ctrl.frequency = $ctrl.customOrderLeaseFrequency;
                        $ctrl.leaseAprRate = $ctrl.customOrderLeaseApr;
                        $ctrl.leaseEirRate = $ctrl.customOrderLeaseEir;
                        $ctrl.leaseDuration = $ctrl.customOrderLeaseTerm;
                    }

                    $ctrl.changeDropdownLabel();

                    $('.C_SB-car-calculator .fcaicon-payment-calculator').hide();
                }

                $ctrl.fetchDealerDistanceFromUserLocation(dealerCode);

                $( window ).resize(function() {
                    let width = $( window ).width();

                    if (width > 768) {
                        if ($('.simple-accordion-content').css('display') == 'none') {
                            $('.simple-accordion-content').show();
                        }
                    }
                });
            }

            // Once a trim has been determined, parse the data to show detailed information
            // rather than just codes
            $scope.$on('packages-selector:selected-trim-updated', (_, data) => {
                $ctrl.modelYearId = configService.modelYearId;
                $ctrl.nameplateCode = configService.nameplateCode;
                $ctrl.year = configService.year;
                $ctrl.brandCode = configService.brandCode;
                $ctrl.acode = configService.acode;
                $ctrl.provinceCode = configService.provinceCode;

                trimService.getTrimPricing(configService.provinceCode, configService.modelYearId, configService.brandCode, configService.nameplateCode, configService.year)
                $ctrl.initStickySummary();
                // Update both the trim and the package to change the displayed information
                $ctrl.selectedTrim = data.selectedTrim;
                $ctrl.mfgCode = $ctrl.selectedTrim.mfgCode;
                $ctrl.selectedTrim['trimName'] = $ctrl.trimName;
                $ctrl.selectedPackage = data.selectedTrim.packages[0];

                $ctrl.setCTAUrls();
            });

            $scope.$on('scratch-save-updated:scratch-save', (_, data) => {
                $ctrl.scratchSave = data.scratch;
                $ctrl.setCTAUrls();
            });

            $rootScope.$on('navigation: active-step-changed', (_, data) => {
                $ctrl.step = data.step;
                $ctrl.onStepSummary = data.step === 'summary';
            });

            $rootScope.$on('navigation: payment-type-changed', (_, data) => {
                $ctrl.paymentType = data.type;
                // save Payment type in config service to update each price option
                configService.setPaymentType(data.type);
            });

            $scope.$on('packages-selector:selected-package-updated', (_, data) => {
                // Update the package to change the displayed information
                $ctrl.selectedPackage = data.package;
                configService.updateCalculatorParameters();
            });

            $scope.$on('packages-selector:pricing', $ctrl.onPackagesSelectorPricing);

            $scope.$on('calculator:update-payment', $ctrl.onCalculatorUpdatePayment);

            $rootScope.$on(fcaGeolocator.getLocationUpdatedEvent(), (_, args) => {
                const location = args[0];

                $ctrl.locationLabel = window.FCA_SITES_CONFIG.location[location.province].desc;
            });

            $rootScope.$on('options-selector:paint-updated', (_, data) => {
                $ctrl.paint = data.paint.substr(0, 3);
                $ctrl.paintHex = encodeURIComponent(data.hexCode.toUpperCase());
                $ctrl.setCTAUrls();
                configService.setPaintCode(data.paint);
            });

            $rootScope.$on('jelly:exterior-jelly', (_, data) => {
                $ctrl.jellyUrl = data;
            });

            $rootScope.$on('calculator:get-current-icentives-total', (_, data) => {
                $ctrl.totalIncentives = data;
            });
        };

		const locationIsAccurate = (loc) => {
			return (
				location &&
				(loc.locationBaseOn === "userInput" ||
					(loc.locationBaseOn === "browser" &&
						(loc.forceByIp === false || loc.forceByIp === "false")))
			);
		};


        $ctrl.fetchDealerDistanceFromUserLocation = (dealerCode) => {
            fcaGeolocator.detectCurrentPosition().then((position) => {
                if (typeof position === "object" && (position !== null)) {
                    fcaGeolocator.reverseGeocode(position).then((location) => {
                        $ctrl.getSingleDealer(location.longitude, location.latitude, dealerCode);
                    });
                } else {
					if(locationIsAccurate(position)) {
						fcaGeolocator.getCurrentLocation().then((location) => {
							$ctrl.getSingleDealer(location.longitude, location.latitude, dealerCode);
						})
							.catch((reason) => {
								console.log('error detecting position ' + reason);
							});
					}
                }
            });
        }

        $ctrl.getSingleDealer = (longitude, latitude, dealerCode) => {
            let uri = '/data/dealers/expandable-radius';
            let brand = window.FCA_SITES_CONFIG.name;
            let radius = 50;
            let url = `${uri}?` + `brand=${brand}&longitude=${longitude}&latitude=${latitude}&radius=${radius}`;

            $http.get(url).then((r) => {
                let d = r.data.dealers;

                let currentDealer = d.find((o) => {
                    return o['code'] === dealerCode
                });

                configService.setDealer(currentDealer);
                $ctrl.customOrderDealerDistance = Math.round(currentDealer.distance || 0);
                $ctrl.customOrderDealerName = currentDealer.name;
            }).catch(function(e) {
                console.error('error retrieving dealers', e);
            });
        };

        /**
         * Triggered by the event 'packages-selector:pricing'
         */
        $ctrl.onPackagesSelectorPricing = (event, data) => {
            let paymentInfo = {
                netAmount: $ctrl.netAmount
            };

            if (data.pricing) {
                if(data.pricing.finance) {
                    $ctrl.minFinanceTermObj = {
                        duration: data.pricing.finance.term,
                        rate: data.pricing.finance.rate
                    };

                    paymentInfo.financeTerm = data.pricing.finance.term;
                    paymentInfo.financeRate = data.pricing.finance.rate;
                }

                if(data.pricing.lease) {
                    $ctrl.minLeaseTermObj = {
                        duration: data.pricing.lease.term,
                        rate: data.pricing.lease.rate,
                        residual: data.pricing.lease.residual
                    };

                    paymentInfo.leaseTerm = data.pricing.lease.term;
                    paymentInfo.leaseRate = data.pricing.lease.rate;
                    paymentInfo.residualRate = data.pricing.lease.residual;
                }

                configService.setPaymentInfo(paymentInfo);
                $ctrl.optionsAmount = Number(data.pricing.options.msrp || 0);
            }
        };

        /**
         * Triggered by the event 'calculator:update-payment'
         *
         * When the calculator updates the payment
         */
        $ctrl.onCalculatorUpdatePayment = (event, data) => {
            $ctrl.leasePerWeek = data.leasePayment;
            $ctrl.leaseDuration = $filter('number')(data.leaseTerm, 0);
            $ctrl.financePerWeek = data.financePayment;
            $ctrl.financeDuration = $filter('number')(data.financeTerm, 0);
            $ctrl.frequency = data.frequency;
            $ctrl.discounts = data.discounts;
            $ctrl.discountsForDisplay = data.discountsForDisplay;
            $ctrl.hasHybridIncentives = data.hasHybridIncentives;
            $ctrl.disclaimer = data.disclaimer;

            $ctrl.downPayment = data.downPayment;
            $ctrl.tradeIn = data.tradeInValue;
            $ctrl.oweOnTrade = data.tradeOwed;

            if ($ctrl.leasePerWeek) {
                $ctrl.hasLease = true;
                if (data.currentRates.lease) {
                    $ctrl.leaseEirRate = $filter('number')(data.currentRates.lease.eir, 2);
                    $ctrl.leaseAprRate = $filter('number')(data.currentRates.lease.apr, 2);
                }
            }
            if (data.currentRates.finance) {
                $ctrl.financeEirRate = $filter('number')(data.currentRates.finance.eir, 2);
                $ctrl.financeAprRate = $filter('number')(data.currentRates.finance.apr, 2);
            }
            // this is the cash net amount
            $ctrl.netAmount = data.cashNetAmount;
            $ctrl.useBestRate = data.useBestRate;
            $ctrl.hasAltFinance = data.hasAltFinance;
            $ctrl.hasAltLease = data.hasAltLease;
            $ctrl.hasLease = data.hasLease;

            $ctrl.changeDropdownLabel();
        };

        $ctrl.changeDropdownLabel = () => {
            // Return label for the pricing dropdown in the director bar
            // The return key is translated in the html file
            if ($ctrl.paymentType === 'cash') {
                $ctrl.dropdownLabel = 'build-and-price.director.cash';

            } else if ($ctrl.paymentType === 'finance' || $ctrl.paymentType === 'altFinance') {
                if ($ctrl.hasAltFinance) {
                    $ctrl.dropdownLabel = $ctrl.useBestRate ? 'build-and-price.common.finance-best-rate' : 'build-and-price.common.finance-best-payment';
                } else {
                    $ctrl.dropdownLabel = 'build-and-price.director.finance';
                }
            } else if ($ctrl.paymentType === 'lease') {
                $ctrl.dropdownLabel = 'build-and-price.director.lease';
            }
        };

        $ctrl.getDrivetrainDescription = code => {
            return trimService.getDrivetrainDescription(code);
        };

        $ctrl.getBoxLengthDescription = code => {
            return trimService.getBoxLengthDescription(code);
        };

        $ctrl.getCabTypeDescription = code => {
            return trimService.getCabTypeDescription(code);
        };

        $ctrl.getRearWheelDescription = code => {
            return trimService.getRearWheelDescription(code);
        };

        $ctrl.getWheelbaseDescription = code => {
            return trimService.getWheelbaseDescription(code);
        };

        // These functions are needed because in english,
        // the box lengths and the wheelbase have unescaped double quotes for the inches
        // This breaks when translating in the HTML template
        $ctrl.translateBoxLength = code => {
            let translated = $ctrl.getBoxLengthDescription(code);
            return $translate.instant(
                'build-and-price.director.box-length',
                { 'boxLength' : translated }
            )
        };

        $ctrl.translateWheelbase = code => {
            let translated = $ctrl.getWheelbaseDescription(code);
            return $translate.instant(
                'build-and-price.director.wheel-base',
                { 'wheelBase' : translated }
            )
        };

        $ctrl.initStickySummary = () => {
            new Waypoint.Sticky({
                element: $('.js-C_SB-holder')[0],
                direction: 'up',
                offset: 'bottom-in-view'
            });

            let refreshWaypointTimer = window.setInterval(() => {
                Waypoint.refreshAll();
            }, 500);

            $ctrl.makeDirectorScrollable();
        };

        $ctrl.setCTAUrls = () => {
            if($ctrl.language === 'fr') {
               $ctrl.contactDealerUrl = `custom-build-concessionnaire`;
            }
            $ctrl.contactDealerCTA =
                `/${$ctrl.language}/${$ctrl.contactDealerUrl}` +
                `${$ctrl.queryParams}` +
                `#brand=${$ctrl.brandCode}` +
                `&nameplate=${$ctrl.nameplateCode}` +
                `&year=${$ctrl.year}` +
                `&modelYearId=${$ctrl.modelYearId}` +
                `&trim=${$ctrl.trimName}` +
                `&mfgCode=${$ctrl.mfgCode}` +
                `&paint=${$ctrl.paint}` +
                `&acode=${$ctrl.acode}` +
                `&provinceCode=${$ctrl.provinceCode}` +
                `&scratchSave=${configService.scratchSave}`;
            if($ctrl.hashParameters.dealerId) {
                $ctrl.contactDealerCTA += `&dealer=${$ctrl.hashParameters.dealerId}`;
            }

            if (window.FCA_SITES_CONFIG.subBrandCode === window.FCA_SITES_CONFIG.name) {
                const brand = window.FCA_SITES_CONFIG.brandCode;
                const brandPath = window.FCA_SITES_CONFIG.brandSiteUrls[brand];

                $ctrl.contactDealerCTA = `${brandPath}${$ctrl.contactDealerCTA}`
            }

            // check if we're in iframe mode
            let dealerFromStorage = JSON.parse(sessionStorage.getItem('dealer-lock'));
            if (dealerFromStorage) {
                // check if the vendor configuration is overrided by the iframe
                let vendor = sessionStorage.getItem('vendor');

                // if there's no vendor in session storage, use the vendor from the dealer object
                if (!vendor || vendor === '') {
                    vendor = dealerFromStorage.vendor;
                }

                // use the vendor url specified by iframe parameters
                let dealerUrl = sessionStorage.getItem('dealer-url');

                // if there's no dealer url in session storage, use the url from the dealer object
                if (!dealerUrl || dealerUrl === '') {
                    dealerUrl = dealerFromStorage.url;
                }

                // if the dealer url does not start with a protocol add https
                if (!dealerUrl.startsWith('http://') && !dealerUrl.startsWith('https://')) {
                    dealerUrl = "https://" + dealerUrl;
                }

                // retrieve the vendor mapping
                $http.get(`/data/dealer-website/mapping?vendor=${vendor}`)
                    .then(response => {
                        if (response.data) {
                            let vendorMapping = response.data;
                            if (vendorMapping) {
                                // get the make mapping
                                let makeMapping = (vendorMapping.makes || []).find(item => {
                                    return item.code === $ctrl.brandCode;
                                });
                                // get the model mapping
                                let modelMapping = (vendorMapping.models || []).find(item => {
                                    return item.id === $ctrl.modelYearId;
                                });
                                let srp = '';
                                if ($ctrl.language == 'fr') {
                                    srp = vendorMapping.srp.fr || '';
                                } else {
                                    srp = vendorMapping.srp.en || '';
                                }
                                srp = srp.split('{{url}}').join(dealerUrl);
                                srp = srp.split('{{trim}}').join($ctrl.trimName);
                                srp = srp.split('{{year}}').join($ctrl.year);
                                if (makeMapping) {
                                    srp = srp.split('{{make}}').join(makeMapping.name);
                                }
                                if (modelMapping) {
                                    srp = srp.split('{{model}}').join(modelMapping.name);
                                }
                                $ctrl.inventoryCTA = srp;
                            }
                        }
                    }).catch(function (e) {
                    throw new Error('error retrieving vendor mapping: ' + e);
                });
            } else {
				const queryParams = $ctrl.queryParams !== "" ? $ctrl.queryParams : "?";

                $ctrl.inventoryCTA =
                    `${$ctrl.baseSniBuildUrl}` +
                    `${queryParams}` +
                    `paymentMode=cash&year=${$ctrl.year}&trim=${$ctrl.trimName}` +
                    `&modelYearId=${$ctrl.modelYearId}` +
                    `&acode=${$ctrl.acode}` +
                    `&color=${$ctrl.paintHex}` +
                    `&trimPkg=${$ctrl.selectedPackage.code}` +
                    `&trimOpts=${$ctrl.paint}`;
            }
        };

        $ctrl.isSaved = () => {
            return configService.builtVehicleSaved !== null;
        };

        // TODO RM: same functoin in payment-summary.component.js so put this function in userAccountService!!!
        $ctrl.saveYourBuild = (event) => {
            if (userAccountService.isLibraryAvailable()) {
                let pricingInfo = {
                    netAmount: $ctrl.netAmount
                };
                if($ctrl.minFinanceTermObj) {
                    pricingInfo["financeTerm"] = $ctrl.minFinanceTermObj.duration;
                    pricingInfo["financeRate"] = $ctrl.minFinanceTermObj.rate;
                }
                if($ctrl.minLeaseTermObj) {
                    pricingInfo["leaseTerm"] = $ctrl.minLeaseTermObj.duration;
                    pricingInfo["leaseRate"] = $ctrl.minLeaseTermObj.rate;
                    pricingInfo["residualRate"] = $ctrl.minLeaseTermObj.residual;
                }

                if (UA.isUserLoggedIn()) {
                    configService.saveConfiguration($scope, pricingInfo);
                } else {
                    $rootScope.configurationToSave = pricingInfo;
                }

				$ctrl.savedVehicle = true;
            }
        };

        $ctrl.displaySNIMode = () => {
			const hasCustomOrder = hasCustomOrderParam();
            if (!$ctrl.inIframeMode && $ctrl.hasCustomOrderSni === 'true' && hasCustomOrder && !$ctrl.affiliateMode) {
                $ctrl.showSNIMode = true;
            }
            if ($ctrl.inIframeMode || $ctrl.affiliateMode || $ctrl.hasCustomOrderSni === 'false' || !hasCustomOrder) {
                $ctrl.showSNIMode = false;
            }
        }

        $ctrl.displayAffiliateMode = () => {
            if (!$ctrl.inIframeMode && $ctrl.customOrderActivated === 'true' && $ctrl.affiliateMode) {
                $ctrl.showAffiliateMode = true;
            }
            if ($ctrl.inIframeMode || !$ctrl.affiliateMode || $ctrl.customOrderActivated === 'false') {
                $ctrl.showAffiliateMode = false;
            }
        }

        $ctrl.saveCustomOrder = () => {

            const affiliateId = getStoredAffiliateId();
            const affiliateEncryptedParam = getAffiliateParam('affiliateEncryptedParam');
            const affiliateEaProgram = getAffiliateParam('affiliateEaProgram');

            const hasExteriorImage = $('#selected-exterior-color > img').attr('data-has-image') === 'true' ? true : false;

            const exteriorColorUrl = hasExteriorImage ? $('#selected-exterior-color > img').attr('src') : null;
            const wheelColorUrl = $('#selected-wheel-color > img').attr('src');

            const urlParams = new URLSearchParams(window.location.search);
            const urlBuildId = urlParams.get('buildId') || null;
            const fcaCurrency = $filter('fcaCurrency');
            const currencyNetAmount = fcaCurrency($ctrl.netAmount).replace(' ', '');
            const incentives = fcaCurrency($ctrl.totalIncentives).replace(' ', '');

            const persistentUid = urlParams.get('pid') || fcaUserIdPersistentService.getCookie('userId') || null;


            const pricing = pricingCustomOrder(
                configService.convertPriceInNumber(currencyNetAmount),
                configService.paymentType,
                configService.convertPriceInNumber($ctrl.financeDuration) || null,
                configService.convertPriceInNumber($ctrl.financeAprRate) || null,
                $ctrl.frequency,
                configService.convertPriceInNumber($ctrl.leaseDuration) || null,
                configService.convertPriceInNumber($ctrl.leaseAprRate)|| null,
                $ctrl.frequency,
                $ctrl.downPayment,
                $ctrl.tradeIn,
                $ctrl.oweOnTrade,
                $ctrl.optionsAmount,
                configService.convertPriceInNumber(incentives)
            );

            const payload = payloadCustomOrder(
                configService.provinceCode,
                configService.year,
                configService.subBrandCode || configService.brandCode,
                configService.nameplateCode,
                configService.language,
                affiliateId,
                affiliateEncryptedParam,
                affiliateEaProgram,
                $ctrl.campaignCode || null,
                $ctrl.trimName,
                $ctrl.trimNameEn,
                $ctrl.trimNameFr,
                configService.acode,
                configService.modelYearId,
                null,
                persistentUid,
                configService.selectedPackage.code,
                configService.scratchSave,
                $ctrl.jellyUrl,
                wheelColorUrl,
                exteriorColorUrl,
                $ctrl.engineIcon || null,
                $ctrl.transmissionIcon || null,
                configService.extractPackageAndOptionsCode().toString(),
                null,
                urlBuildId,
                pricing
            );

            $ctrl.isPostLoading = true;

            $http.post(saveCustomOrderAPI, payload).then(response => {
                const buildId = response.data.buildId;

                $ctrl.isPostLoading = false;
                redirectToDealersPage($ctrl.language, buildId, persistentUid);
            }).catch(function (e) {
                $ctrl.isPostLoading = false;
                throw new Error('error in saving custom order', e);
            });
        };

        $scope.$on('ua:userSignInSuccess', (event, data) => {
            UA.retrieveAndCheckIsUserLoggedIn().finally(() => {
                configService.saveConfiguration($scope, $rootScope.configurationToSave);
            });
        });

        $ctrl.initLocation = () => {
            $ctrl.location = userLocation.getLocation();
            $ctrl.locationLabel = userLocation.getLocationLabel();
        };

        $ctrl.onSummaryClick = () => {
            $rootScope.$broadcast('navigation: active-step-change', {
                'step': 'summary',
            });
        };

        $ctrl.onCalculatorClick = () => {
            let calculatorStep = 'summary';
            if ($ctrl.isCustomOrder) {
                calculatorStep = 'payment';
            }
            $rootScope.$broadcast('navigation: active-step-change', {
                step: calculatorStep,
                section: 'calculator'
            });
        };

        $ctrl.onPrintClick = () => {
            window.print();
        };

        $ctrl.changePaymentType = paymentType => {
            if ($ctrl.isModelStep) {
                $ctrl.paymentType = paymentType;
                $ctrl.changeDropdownLabel();
            }

            $rootScope.$broadcast('navigation: payment-type-changed', {
                'type': paymentType,
            });
        };

        $ctrl.focusBackOnElement = $event => {
            const focusElement = $($event.target).closest('[fca-simple-toggle]').find('.C_SB-pricing-dropdown-toggle');

            if (focusElement) {
                focusElement.focus();
            }
        };

        $ctrl.getDiscountTotal = (paymentType) => {
            let discounts = $ctrl.discounts;
            if (!discounts) {
                return;
            }

            let totalDiscount = 0;
            switch (paymentType) {
                case 'cash':
                    if(discounts.cash && discounts.cash.beforeTax) {
                        totalDiscount += discounts.cash.beforeTax;
                    }
                    if(discounts.cash && discounts.cash.afterTax) {
                        totalDiscount += discounts.cash.afterTax;
                    }
                    return totalDiscount;
                case 'finance':
                    if(discounts.finance && discounts.finance.beforeTax) {
                        totalDiscount += discounts.finance.beforeTax;
                    }
                    if(discounts.finance && discounts.finance.afterTax) {
                        totalDiscount += discounts.finance.afterTax;
                    }
                    return totalDiscount;
                case 'lease':
                    if(discounts.lease && discounts.lease.beforeTax) {
                        totalDiscount += discounts.lease.beforeTax;
                    }
                    if(discounts.lease && discounts.lease.afterTax) {
                        totalDiscount += discounts.lease.afterTax;
                    }
                    return totalDiscount;
            }
        };

        $ctrl.hasDiscount = paymentType => {
            return $ctrl.discounts && $ctrl.discounts[paymentType] && $ctrl.discounts[paymentType].hasDiscount;
        };

        $ctrl.getDisclaimer = paymentType => {
            return $ctrl.disclaimer[paymentType];
        };

        $ctrl.hasDisclaimer = paymentType => {
            return $ctrl.disclaimer && $ctrl.disclaimer[paymentType];
        };

        $ctrl.paymentTypeDropdownToggle = (event) => {
          if (event) {
            event.preventDefault();
          }

          if ($ctrl.menuOpened) {
            $ctrl.menuOpened = false;
          } else {
            $ctrl.menuOpened = true;
          }
        };

        /* SCROLLABLE DIRECTOR FUNCTION */
        /* Note: "resize" event does not work on FF if zoom is on text only */

        $ctrl.makeDirectorScrollable = () => {
            const directorModule = $('.C_BP-zone-director .C_SB-holder');

            if (directorModule.outerHeight() > 200) {
                directorModule.addClass('scrollable-director');
            } else {
                directorModule.removeClass('scrollable-director');
            }
        };

        $(window).on('resize', functionExecutionControl.debounce($ctrl.makeDirectorScrollable, 400));
    }
})();
