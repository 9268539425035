(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaF2MCNewsEvent', fcaF2MCNewsEvent);

	function fcaF2MCNewsEvent() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				analyticscategory: '@',
				analyticsid: '@'
			},
			controllerAs: 'fcaF2MCNewsEvent',
			controller: fcaF2MCNewsEvent,
		};

		function fcaF2MCNewsEvent($element) {
			const $F2MCNewsEvent = this;

			let analyticscategory = '';
			let analyticsid = '';

			$F2MCNewsEvent.$onInit = () => {
				const elements = [
					$element[0].querySelector('.slick-prev'),
					$element[0].querySelector('.slick-next')
				];

				const options = {
					attributes: true
				};

				const observer = $F2MCNewsEvent.createObserver($F2MCNewsEvent.manageSlickTabbing);
				$F2MCNewsEvent.bindObserver(observer, elements, options);
			};

			$F2MCNewsEvent.createObserver = callbackFunction => {
				return new MutationObserver(callbackFunction);
			};

			$F2MCNewsEvent.bindObserver = (observer, elements, options) => {
				elements.forEach( element => {
					observer.observe(element, options);
				});
			};

			$F2MCNewsEvent.manageSlickTabbing = (mutationsList) => {
				mutationsList.forEach(mutations => {
					if (mutations.attributeName === 'class') {
						if (mutations.target.classList.contains('slick-disabled')) {
							mutations.target.setAttribute('tabindex', '-1');
							if (document.activeElement === mutations.target) {
								document.activeElement.blur();
							}
						} else {
							mutations.target.setAttribute('tabindex', '0');
						}
					}
				});
			};

			$F2MCNewsEvent.customDots = (slider, i) => {
				var num = i + 1;
				analyticscategory = $F2MCNewsEvent.analyticscategory;
				analyticsid = $F2MCNewsEvent.analyticsid;
				return'<button type="button" role="button" tabindex="0" data-analyticscategory="' + analyticscategory + '" data-analyticsid="' + analyticsid + '" data-analyticsvalue="section-#' + num + '">' + num + '</button>';
			};
		}
	}
})();
