(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFleetVehiclesGroup', fcaFleetVehiclesGroup);

	function fcaFleetVehiclesGroup() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'fcaFleetVehiclesGroup',
			controller: fcaFleetVehiclesGroup,
		};

		function fcaFleetVehiclesGroup($scope, $element, $window, $timeout, functionExecutionControl) {
			const $ctrl = this;

			$ctrl.toggleIsExpanded = false;
			$ctrl.descriptionExpand = false;

			$ctrl.$onInit = () => {
				$ctrl.bindResize();

				$ctrl.longDescElements = $element.find('.featured-item-long-desc');
				$ctrl.vehicleTiles = $element.find('.featured-item--vehicle');

				$ctrl.mobileCarouselWrapper = $element.find('.mobile-carousel-wrapper');
				$ctrl.hasMobileCarousel = false;
				$ctrl.carouselArrowPrev = $element.find('.carousel-custom-controls .prev');
				$ctrl.carouselArrowNext = $element.find('.carousel-custom-controls .next');
				$ctrl.carouselDots = $element.find('.carousel-custom-controls .dots');

				// elements with dynamic height resize
				$ctrl.titleElements = $element.find('.featured-item--vehicle .featured-item-title');
				$ctrl.excerptElements = $element.find('.featured-item--vehicle .featured-item-excerpt');
				$ctrl.specsListElements = $element.find('.featured-item--vehicle .featured-item-specs-list');
				$ctrl.jellyElements = $element.find('.featured-item--vehicle .featured-item-jelly');

				$timeout(() => {
					$ctrl.updateAllDynamicHeight();
					$ctrl.setSlickCarousel();
				});
			};

			$ctrl.bindResize = () => {
				window.addEventListener('resize', (event) => {
					functionExecutionControl.debounce($ctrl.handleResize(), 400);
				});
			};

			$ctrl.handleResize = () => {
				$ctrl.updateAllDynamicHeight();
				$ctrl.setSlickCarousel();
			};

			$ctrl.toggleExpand = () => {
				$ctrl.toggleIsExpanded = !$ctrl.toggleIsExpanded;

				$ctrl.updateAllDynamicHeight();
				$ctrl.setSlickCarousel();
			};

			$scope.$on("toggleDescriptions", (e, data) => {
				$ctrl.descriptionExpand = !$ctrl.descriptionExpand;

				$ctrl.updateAllDynamicHeight();
			});

			$ctrl.setDynamicHeight = (elements) => {
				if (elements && elements.length > 1) {
					// reset to auto
					elements.css('height', 'auto');

					let largerHeight = 0;

					elements.each(function() {
						if (this.offsetHeight > largerHeight) {
							largerHeight = this.offsetHeight;
						}
					});

					if (largerHeight > 0) {
						// output larger height to all elements
						elements.css('height', largerHeight + 'px');
					}
				}
			};

			$ctrl.updateAllDynamicHeight = () => {
				$timeout(() => {
					$ctrl.setDynamicHeight($ctrl.longDescElements);
					$ctrl.setDynamicHeight($ctrl.titleElements);
					$ctrl.setDynamicHeight($ctrl.excerptElements);
					$ctrl.setDynamicHeight($ctrl.specsListElements);
					$ctrl.setDynamicHeight($ctrl.jellyElements);
				});
			};

			$ctrl.setSlickCarousel = () => {
				if ($ctrl.vehicleTiles.length > 1) {
					/* Initializes a slick carousel only on mobile screens when more than 1 item */
					if ($window.innerWidth > 1023) {
						if ($ctrl.mobileCarouselWrapper.hasClass('slick-initialized')) {
							$ctrl.mobileCarouselWrapper.slick('unslick');
						}

						$ctrl.hasMobileCarousel = false;

					} else {
						if (!$ctrl.mobileCarouselWrapper.hasClass('slick-initialized')) {
							$ctrl.mobileCarouselWrapper.slick({
								slidesToShow: 1.1,
								slidesToScroll: 1,
								mobileFirst: true,
								dots: true,
								arrows: true,
								nextArrow: $ctrl.carouselArrowNext,
								prevArrow: $ctrl.carouselArrowPrev,
								appendDots: $ctrl.carouselDots,
								infinite: false
							});

							$ctrl.hasMobileCarousel = true;
						}

						$timeout(() => {
							$ctrl.mobileCarouselWrapper.slick('refresh');
						});
					}
				}
			};

			$scope.$on("tabSelection", (e, selectedTab) => {
				$timeout(() => {
					$ctrl.setSlickCarousel();
					$ctrl.updateAllDynamicHeight();

					if($ctrl.mobileCarouselWrapper.hasClass('slick-initialized')) {
						$ctrl.mobileCarouselWrapper.slick('slickGoTo', 0);
					}
				});
			});

			$scope.$watch('fcaFleetVehiclesGroup.toggleIsExpanded', newValue => {
				if (newValue) {
					$ctrl.setSlickCarousel();
					$ctrl.updateAllDynamicHeight();
				}
			});
		}
	}
})();
