(function() {
	angular
		.module('fca.brandApp')
		.directive('fcaFeaturedOfferTile', fcaFeaturedOfferTile);

	function fcaFeaturedOfferTile() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {},
			controllerAs: 'featuredOfferTile',
			controller: FcaFeaturedOfferTileController,
		};

		function FcaFeaturedOfferTileController($element) {
			'ngInject';

			this.activeYear = 0;
			this.showTile = true;
			let $carousel = $('.daa-similar-offers slick');

			this.$postLink = () => {
				if ( !$.trim( $element.find('.featuredOffer-switchableContent').html() ).length ) {
					this.showTile = false;
				}

				this.showFeaturedOffersFirst();
			};

			this.setActiveYear = (year) => {
				this.activeYear = year;
			};

			/* Show the year that contains a featured offer first */
			this.showFeaturedOffersFirst = () => {
				const nameplateOffers = $element.find("[data-is-featured-offer]");
				let lastFeaturedOfferModelYear = null;
				for (let i = 0; i < nameplateOffers.length; i++) {
					const offerTile = nameplateOffers[i];
					if ( offerTile.getAttribute('data-is-featured-offer') === "true" ) {
						lastFeaturedOfferModelYear = parseInt(offerTile.getAttribute('data-model-year'));
					}
				}
				if (lastFeaturedOfferModelYear != null) {
					this.setActiveYear(lastFeaturedOfferModelYear);
				}
			}

			$carousel.on('init afterChange', function(event, slick) {
				setTimeout(function() {
					slick.$slides.each((idx, item) => {
						if($(item).hasClass('slick-current')) {
							$(item).removeAttr('aria-hidden');
						}
					});
				}, 100);
			});
		}
	}
})();
