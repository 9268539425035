(function () {
	angular
		.module('fca.partial')
		.directive('fcaPartial', fcaPartial);

	function fcaPartial() {
		return {
			restrict: 'A',
			scope: true,
			bindToController: {
				destinationId: '@',
				zoneName: '@',
				clearDiv: '@'
			},
			controllerAs: '$partialCtrl',
			controller: FcaPartialCtrl
		};
	}

	function FcaPartialCtrl(fcaPartialService, $timeout, $scope, $compile, $window, $element, $rootScope) {
		'ngInject';
		let $partial = this;
		let lastScrollY = 0;
		let scrollingIsPaused = false;
		let dialogRef;
		let mobileToolbarIsOpen = false;

		$partial.isLoading = false;
		$partial.isDisabled = false;

		const KEY_ESC = 27;

		const onKeyDown = (event) => {
			// ESCAPE key pressed
			if( event.keyCode === KEY_ESC ) {
				$window.document.removeEventListener('keydown', onKeyDown);
				$rootScope.$emit('partial:close-partial');
				$partial.closePartial();
			}
		};

		window.PubSubManager.publish("EVENT.fcaPartial.fcaPartialService", fcaPartialService);

		$partial.$onInit = () => {
			fcaPartialService.registerPartialController($partial.zoneName, $partial);
			$partial.showDrawer = false;

			$rootScope.$on('partial:disable-partials', () => {
				$partial.isDisabled = true;
			});

			$rootScope.$on('partial:close-partial', () => {
				$partial.isDisabled = false;
				$partial.closePartial();
			});

			$rootScope.$on('partial:invoke-partial', (event, data) => {
				if(data === $partial.destinationId) {
					$partial.invokePartial();
				}
			});

			$rootScope.$on('tool-bar:pause-scrolling', () => {
				if(!mobileToolbarIsOpen) {
					mobileToolbarIsOpen = true;
				}

				if(!scrollingIsPaused) {
					scrollingIsPaused = true;
				}
			});

			$rootScope.$on('tool-bar:resume-scrolling', () => {
				if(scrollingIsPaused) {
					scrollingIsPaused = false;
				}

				if(!mobileToolbarIsOpen) {
					mobileToolbarIsOpen = false;
				}
			});

			$scope.$on('component:loaded', () => {
				if($partial.showDrawer && dialogRef) {
					dialogRef.refresh();
				}
			});

			$scope.$on('tool-bar:refresh-focus', () => {
				if($partial.showDrawer && dialogRef) {
					dialogRef.refresh();
				}
			});

			$rootScope.$on('tool-bar:refresh-focus-hr-form', () => {
				if($partial.showDrawer && dialogRef) {
					dialogRef.refresh();
				}
			});

			$partial.enableDisablePartialElements(false);
		};

		/**
		 * Set the net trade-in value
		 * @param checkout
		 */

		$partial.invokePartial = () => {
			if (!$partial.isDisabled && !$partial.isLoading) {
				if ($partial.isDivEmpty() && $partial.clearDiv === 'false') {
					$partial.isLoading = true;
					$partial.emptyPartial();

					fcaPartialService.getZoneContent(
						$partial.zoneName,
						(responseContent) => {
							// Success
							$partial.isLoading = false;
							$partial.setPartialContent(responseContent);
							$partial.managePartialOpen();
						},
						(errorMessage) => {
							// Error
							$partial.isLoading = false;
							$partial.emptyPartial();
						}
					);
				} else {
					$partial.managePartialOpen();
				}
			}
		}

		$partial.managePartialOpen = () => {
			$partial.toggleDrawer();
			$partial.pauseScrolling();
			$partial.focusTrapActive();
			$rootScope.$emit('partial:disable-partials');

			$element.bind('click', (event) => {
				$partial.manageClick(event);
			});

			$window.document.addEventListener('keydown', onKeyDown);
			$partial.enableDisablePartialElements(true);
		}

		$partial.manageClick = (event) => {
			if ($partial.showDrawer && !$partial.isLoading && $(event.target).hasClass('partial-overlay-background')) {
				$rootScope.$emit('partial:close-partial');
			}
		}

		$partial.emptyPartial = () => {
			if($partial.clearDiv === 'false') {
				let destinationDiv = $partial.getDestinationDivElement();
				destinationDiv.empty();
			}
		}

		$partial.isDivEmpty = () => {
			var result = document.querySelector('#' + $partial.destinationId).innerHTML.trim();
			return result == "";
		}

		$partial.toggleDrawer = () => {
			$partial.showDrawer = !$partial.showDrawer;
		}

		$partial.getDestinationDivElement = () => {
			let divElement = $element.find('#' + $partial.destinationId);
			return divElement;
		}

		$partial.getOverlayWrapperDivElement = () => {
			let overlayWrapper = $element.find('#' + $partial.destinationId + '-wrapper');
			return overlayWrapper;
		}

		$partial.pauseScrolling = () => {
			$rootScope.$emit('partial:pause-scrolling');
			if(!scrollingIsPaused) {
				scrollingIsPaused = true;
				$('body').addClass('overflow-hidden');
				$('html').addClass('overflow-hidden');
			}
		}

		$partial.resumeScrolling = () => {
			if(scrollingIsPaused && !mobileToolbarIsOpen) {
				scrollingIsPaused = false;
				$('body').removeClass('overflow-hidden');
				$('html').removeClass('overflow-hidden');
			}
		}

		$partial.setPartialContent = (content) => {
			try {
				let angularObject = angular.element($compile(content)($scope));
				let destinationDiv = $partial.getDestinationDivElement();
				destinationDiv.append(angularObject);
			} catch (exception) {
				console.log('error setting partial content', exception);
			}
		}

		$partial.enableDisablePartialElements = (enable) => {
			setTimeout(() => {
				let destinationId = "#" + $partial.destinationId;
				window.focusFunctions.enableDisableFocusOnElements($(destinationId)[0], enable);
			}, 10);
		}

		$partial.closePartial = () => {
			$partial.enableDisablePartialElements(false);

			const overlayElement = $element.find('.partial-overlay');

			if($partial.showDrawer) {
				$partial.showDrawer = false;
				$partial.resumeScrolling();

				dialogRef = null;

				if(overlayElement) {
					$(overlayElement).bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
						$partial.emptyPartial();
						$(overlayElement).unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd');
					});
				}

				$element.unbind('click', (event) => {
					$partial.manageClick(event);
				});

				$window.document.removeEventListener('keydown', onKeyDown);

				$timeout(() => {
					$scope.$apply();
				});
			}
		}

		$partial.focusTrapActive = () => {
			const wrapperElement = $element.find('.partial-overlay-wrapper');
			const dialogElement = $element.find('.partial-overlay')[0];
			const overlayElement = $element.find('.partial-overlay-background')[0];

			if(dialogElement && overlayElement) {
				wrapperElement.bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
					if (!dialogRef) {
						if (dialogElement && overlayElement) {
							dialogRef = new Dialog(dialogElement, overlayElement);

							//refresh the focus trap by calling Dialog.open again
							dialogRef.open();
						}
					} else {
						dialogRef.open();
					}
					wrapperElement.unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd');
				});
			}
		};

		$rootScope.$on('fca-modal-is-opened', () => {
			$window.document.removeEventListener('keydown', onKeyDown);
		});

		$rootScope.$on('fca-modal-is-closed', () => {
			$window.document.addEventListener('keydown', onKeyDown);
		});

	}
})();
