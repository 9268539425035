/* eslint-disable indent */
(function () {
    angular
        .module('fca.buildAndPrice.jellyLegacy')
        .component('jellyLegacy', {
            controller: JellyLegacy,
            controllerAs: '$ctrl',
            templateUrl: '/build-and-price/jelly-legacy/jelly-legacy.html',
            bindings: {
                acode: '@',
                trim: '<',
                year: '@',
                selectedColourDescription: '<',
                selectedNameplateName: '<',
                id: '<'
            }
        });

    function JellyLegacy($scope, $rootScope, $element, $window, configService, $http) {
        'ngInject';

        let $ctrl = this;

        $ctrl.paint = "";
        $ctrl.baseJellyUrl = "";
        $ctrl.legacyRootURl = FCA_SITES_CONFIG.jelliesUrl;
        $ctrl.jellyLoading = false;
        $ctrl.imageId = "jelly-img";

        $ctrl.$postLink = () => {
            $ctrl.image = $element.find("img[name='jelly-img']");
            if ($ctrl.image) {
                $ctrl.image.get(0).onload = $ctrl.onImageLoaded.bind(this);
            }
        }

        $ctrl.$onInit = () => {
            if ($ctrl.acode === undefined) {
                $ctrl.acode = configService.getAcode();
            }

            if ($ctrl.id !== undefined) {
                $ctrl.imageId = 'jelly-img-' + $ctrl.id;
            }

            $scope.$on('options-selector:paint-updated', (_, data) => {
                $ctrl.paint = data.paint.substr(0, 3);
                $ctrl.generateUrl();
            });
        };

        $ctrl.generateUrl = () => {
            const acode = $ctrl.trim.selectedTrim.acode;
            const mfg = $ctrl.trim.selectedTrim.mfgCode;
            let selectedPackage = configService.getSelectedPackage();
            let selectedPackageCode;
            if (selectedPackage) {
                selectedPackageCode = selectedPackage.code;
            }

            let jellyData;
            let hash;
            let url = $ctrl.legacyRootURl;
            let filename = "";
            url += $ctrl.year;
            url += "/800x510/";

            return $http.get(`/data/jellies/files/brand/${configService.brandCode}/year/${$ctrl.year}/acode/${acode}`, { cache: true}).then((result) => {

                jellyData = result.data.filter((element) => element.filename.indexOf($ctrl.paint) !== -1 );
                if (jellyData
                    && jellyData.length > 1
                    && selectedPackageCode
                    && selectedPackageCode.length == 3) {
                    //filter according to manufacturer code and CPOS 23S -> CC.*_JKJM74_2?S_.*
                    let regexMatch = new RegExp("CC.+?_" + mfg + "_" + selectedPackageCode.substring(0,1) + "." + selectedPackageCode.substring(2) + "_.*" );
                    let jellyForPackage = jellyData.filter((element) => regexMatch.test(element.filename));
                    // fallback to first package if we can't find proper package
                    if (jellyForPackage.length > 0) {
                        jellyData = jellyForPackage;
                    }
                }

                // if no jelly correspond to the paint, fallback on not available jelly image
                if (!jellyData[0]) {
                    jellyData = result.data.filter((element) => element.filename.indexOf('Not_Available') !== -1 );
                }

                if (jellyData[0]) {
                    filename = jellyData[0].filename.split('.')[0];
                    hash = jellyData[0].checksum;
                    url += filename;
                    url += "." + hash + ".png";

                    if ($ctrl.baseJellyUrl !== url) {
                        $ctrl.jellyLoading = true;
                    }
                    $ctrl.baseJellyUrl = url;
                    //we save jelly url in config service to not recalculate it if the configuration is saved
                    configService.saveLegacyJelly(url);
                    $rootScope.$broadcast('jelly:updated-jelly', {
                        'jellyUrl': $ctrl.baseJellyUrl
                    });

                    $rootScope.$broadcast('jelly:exterior-jelly', $ctrl.baseJellyUrl);

                }
            });
        };

        $ctrl.onImageLoaded = () => {
			$scope.$apply(() => {
				$ctrl.jellyLoading = false;
			});
        };

        $ctrl.generateAltTag = () => {
            let altText = ''
            if($ctrl.trim && $ctrl.trim.selectedTrim) {
                altText = $ctrl.selectedNameplateName + ' ' + $ctrl.trim.selectedTrim.trimName + ' ' + $ctrl.selectedColourDescription;
            }
            return altText;
        }
    }
})();
