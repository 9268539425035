(function(ng) {
	'use strict';

	angular
		.module('fca.daaCalculator')
		.component('daaCalculator', {
			templateUrl: '/components/daa-calculator/calculator.html',
			controller: 'daaCalculatorController',
			controllerAs: '$ctrl',
			bindings: {
				options: '<?',
				default: '@',
				onRequest: '&?',
				analyticsCategory: '@',
				analyticsId: '@',
				vehicle: '<?'
			}
		})
		.controller('daaCalculatorController', CalculatorController);

	/**
	 * @ngdoc controller
	 * @name fca.daaCalculator.controller:daaCalculatorController
	 * @description [TODO]
	 * @example
	 * <pre>[TODO]</pre>
	 */
	function CalculatorController(
		$scope,
		ngDialog,
		daaFrequency,
		$http,
		$timeout,
		daaMappingService,
		daaCalculatorValidator,
		gtmAnalytics,
		$translate) {
		'ngInject';

		/* Available options */
		const AVAILABLE_OPTIONS = ['cash', 'finance', 'leasing'];
		/* All offers options */
		this.options = null;
		this.year = null;
		this.model = null;
		/* Current data */
		this.data = null;
		/* Default option */
		this.default = null;
		/* Pending status */
		this.pending = false;
		/* selected rate / program*/
		this.selectedRp = null;
		this.selectedEffectiveRate = null;
		/* Tabs list [TODO remove list property] */
		this.tabs = {
			list: {},
			gaLabels: {},
			gaEventTrigger: 'paymentcal'
		};
		/* Hide tabs if only one exists */
		this.hideTabs = false;
		/* Debouncer $timeout */
		let debouncer = null;
		/* Debounce delay */
		let delay = 500;
		/* Residuals percent */
		this.residuals = {};
		/* Url financing */
		this.applyForFinancingUrl = null;
		/* Disabled contact */
		this.disabledContact = false;
		this.offerDisclaimerTooltipProps = {key: null, value: null};


		this.$onInit = () => {
			this.applyForFinancingUrl = this.options.applyForFinancingUrl;
			// Disabled contact
			this.disabledContact = (this.options.disabledContact === 'true');

			// Build tabs list
			AVAILABLE_OPTIONS.forEach((opts) => {
				if (!this.options[camelCase('hide', opts)]) {
					// Trad. tabs
					let label = opts;
					if (opts === 'leasing') {
						label = 'lease';
					}

					this.tabs.list[opts] = $translate.instant(`offer.${label}`);
					// Push GA labels for tracking
					this.tabs.gaLabels[opts] = opts;
				}
			});

			let list = this.tabs.list || {};
			if (Object.keys(list).length < 2) {
				this.hideTabs = true;
			}

			// Map options
			this.year = this.options.year;
			this.model = this.options.model;
			this.options = daaMappingService.mapOptions(this.options);

			// Set current data
			this.data = this.options[this.default];
			this.selectedRp = this.getSelectedRp(this.data);
			this.selectedEffectiveRate = this.getSelectedEffectiveRate(this.data);
			// Set label frequency
			for (let p in this.options) {
				if (AVAILABLE_OPTIONS.indexOf(p) !== -1 && this.options[p] !== undefined) {
					let label = daaFrequency.getFrequencyStrFromInput(this.options[p].frequency);
					this.options[p].frequencyLabel = label;
				}
			}
			// Set offer Disclaimer tooltip infos
			this.setOfferDisclaimerTooltipProps();
		};

		/**
		 * @ngdoc method
		 * @name changeOfferOptions
		 * @methodOf fca.daaCalculator.controller:daaCalculatorController
		 * @description [TODO]
		 * @param  {String} type [description]
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.changeOfferOptions = (type = 'leasing') => {
			if (this.options.hasOwnProperty(type)) {
				this.data = ng.extend({}, this.options[type]);
			}
		};

		this.request = ($evt) => {
			$evt.preventDefault();
			if (ng.isFunction(this.onRequest)) {
				this.onRequest();
			}
		};

		this.getSelectedRp = (data) => {
			let alternativeProgram = data.alternativeProgram;
			if (data.programChanged) {
				alternativeProgram = !alternativeProgram;
			}
			return alternativeProgram ? data.altRp : data.rp;
		}

		this.getSelectedEffectiveRate = (data) => {
			let alternativeProgram = data.alternativeProgram;
			if (data.programChanged) {
				alternativeProgram = !alternativeProgram;
			}
			return alternativeProgram ? data.altEffectiveRate : data.effectiveRate;
		}

		/**
		 * @ngdoc method
		 * @name getPriceBoxContainerIntro
		 * @methodOf fca.daaCalculator.controller:daaCalculatorController
		 * @description return the price box container intro
		 */
		this.getPriceBoxContainerIntro = () => {
			switch (this.data.type) {
				case 'cash':
					return 'offer.price.feature.intro';
				case 'finance':
					return 'calculator.dialog.finance.title';
				case 'leasing':
					return 'calculator.dialog.lease.title';
				default:
					return '';
			}
		}

		/**
		 * @ngdoc method
		 * @name setOfferDisclaimerTooltipProps
		 * @methodOf fca.daaCalculator.controller:daaCalculatorController
		 * @description set offer Disclaimer Tooltip Properties
		 */
		this.setOfferDisclaimerTooltipProps = () => {
			if(!this.data.type) return;
			this.offerDisclaimerTooltipProps = {
				key: this.options[this.data.type].offerDisclaimer.key,
				value: this.options[this.data.type].offerDisclaimer.value,
			};
		}

		/**
		 * @ngdoc method
		 * @name update
		 * @methodOf fca.daaCalculator.controller:daaCalculatorController
		 * @description [TODO]
		 * @param  {Object} opts [description]
		 * @example
		 * <pre>[TODO]</pre>
		 */
		this.update = (opts) => {
			if (opts.hasOwnProperty('type')) {
				let current = this.options[opts.type];
				if (current !== undefined) {
					let frequencyStrfn = daaFrequency.getFrequencyStrFromInput;
					let label = frequencyStrfn(opts.frequency);
					let trackingFqLabel = frequencyStrfn(opts.frequency, true);
					this.options[opts.type] = ng.extend({}, current, opts, {
						downPayment: opts.dp,
						tradeInValue: opts.tiv,
						frequencyLabel: label,
						frequencyTrackingLabel: trackingFqLabel,
						programChanged: opts.programChanged
					});
					this.selectedRp = this.getSelectedRp(opts);
					this.selectedEffectiveRate = this.getSelectedEffectiveRate(this.options[opts.type]);
					if (parseInt(current.toPay, 10) !== parseInt(opts.toPay, 10)) {
						this.pending = true;
						let qParamsOpts = this.options[opts.type];
						let qType = (opts.type === 'finance') ? 'financing' : opts.type;
						let queryOptions = daaMappingService.mapQueryParams(qParamsOpts, qType);

						debouncer = apiCalculatorValidate(this.model, queryOptions, qType, debouncer, (r) => {
							this.options[opts.type].toPay = Math.round(parseFloat(r.payment, 10));
							//set the program for best payment
							let isAlternativeProgram = (r.alternativeProgram == 'true');
							this.selectedRp = isAlternativeProgram ? this.data.altRp : this.data.rp;
							if (opts.type === 'finance') {
								this.options[opts.type].financePaymentWithTax =
									Math.round(parseFloat(r.financePaymentWithTax, 10));
							} else {
								this.options[opts.type].leasePaymentWithTax =
									Math.round(parseFloat(r.leasePaymentWithTax, 10));
							}

							this.pending = false;
						}, () => {
							this.pending = false;
						});
					}
				}
			}
		};

		/**
		 * @description [TODO]
		 * @param  {Object} opts [TODO]
		 * @param  {String} type [TODO]
		 * @param  {Object} debouncer [TODO]
		 * @param  {Function} success [TODO]
		 * @param  {Function} error   [TODO]
		 * @return {Object}           [TODO]
		 */
		function apiCalculatorValidate(model, opts, type, debouncer, success, error) {
			if (debouncer !== null) {
				$timeout.cancel(debouncer);
				debouncer = null;
			}
			debouncer = $timeout(() => {
				daaCalculatorValidator.validatePayment(model, opts, type).then(success, error);
			}, delay);

			return debouncer;
		}
	}

	function camelCase(str, ...args) {
		args.forEach((s) => {
			str += (s.charAt(0).toUpperCase() + s.slice(1));
		});

		return str;
	}
})(angular);
