/* eslint-disable indent */
(function () {
	angular
		.module('fca.buildAndPrice.jelly')
		.component('jelly', {
			controller: Jelly,
			controllerAs: '$ctrl',
			templateUrl: '/build-and-price/jelly/jelly.html',
			bindings: {
				acode: '@',
				mfgCode: '@',
				trim: '<',
				packageCodeOverride: '@',
				cposVehicleType: '@',
				year: '@',
				brandCode: '@',
				customOptions: '@',
				preferredPaintCode: '@',
				jellyPov: '@',
				jellyParameters: '@',
				jellyAdditionalOptions: '<',
				hasWheels: '<',
				jellyAria: '@',
				jellyThreeSixty: '@',
				showSpinControls: '<',
				numberOfAngles: '@',
				showPovControls: '<',
				showColourWheelsControls: '<',
				carName: '@',
				callback: '&',
				mobileWidth: '@',
				mobileHeight: '@',
				desktopWidth: '@',
				desktopHeight: '@',
				loadingImage: '@',
				selectedColourDescription: '<',
				selectedNameplateName: '<',
				irisExternalJellyDisclaimer: '@?',
			}
		});

	function Jelly($rootScope, $scope, $element, $window, configService, externalConfigLoader) {
		'ngInject';

		const MAX_RETRY = 5;
		const config = externalConfigLoader.loadConfig('FCA_SITES_CONFIG');

		let $ctrl = this;

		//IRIS OPTIONS
		$ctrl.irisRootURl = config.getConfig('irisJellyBaseUrl') + "/mediaserver/iris?client=FCAUS&market=U";
		$ctrl.brand = "";
		$ctrl.vehicle = "";
		$ctrl.tone = "";
		$ctrl.paint = "";
		$ctrl.secondaryPaint = "";
		$ctrl.fabric = "";
		$ctrl.sa = "";
		$ctrl.pov = "";
		$ctrl.dimensions = {};
		$ctrl.background = "";
		$ctrl.urls = "";
		$ctrl.jellyUrl = "";
		$ctrl.baseJellyUrl = "";
		$ctrl.packageCodeOverride = "";
		$ctrl.previousDisabled = false;
		$ctrl.nextDisabled = false;
		$ctrl.povMode = 'E';
		$ctrl.isDragging = false;
		$ctrl.showGradient = "";
		$ctrl.povControlsVisible = false;
		$ctrl.show360Viewer = false;
		$ctrl.jellyLoading = false;
		$ctrl.jellyLoaded = false;

		$ctrl.currentTab = "colors";

		$ctrl.currentRetryAttempt = 0;

		const dragThreshold = 150;
		const defaultNumberOfAngles = 18;
		const maxNumberOfAngles = 36;
		const startingAngle = '03';
		const minAngle = '01';
		const sideViewPov = 'E10';

		//LEGACY OPTIONS
		$ctrl.legacyRootURl = config.getConfig('jelliesUrl');

		$ctrl.$onInit = () => {
			$ctrl.jellyId = Date.now();

			//If no acode exists, meaning we are in the build and price page,
			//request the acode from the configService
			if ($ctrl.acode === undefined) {
				$ctrl.acode = configService.getAcode()
			}

			// Default values
			$ctrl.numberOfAngles = $ctrl.numberOfAngles || defaultNumberOfAngles;
			$ctrl.numberOfAngles = $ctrl.numberOfAngles > maxNumberOfAngles ? maxNumberOfAngles : $ctrl.numberOfAngles;
			$ctrl.showSpinControls = $ctrl.showSpinControls || false;
			$ctrl.showPovControls = $ctrl.showPovControls || false;
			$ctrl.showColourWheelsControls = $ctrl.showColourWheelsControls || false;

			$ctrl.handleDrag();

			$ctrl.brand = $ctrl.getIrisBrandByBrandCode(configService.brandCode);
			$ctrl.pov = $ctrl.jellyPov;
			$ctrl.povMode = $ctrl.pov[0];
			$ctrl.showGradient = $ctrl.pov[0] === "I" ? 'hide-gradient' : "";
			$ctrl.background = "white";

			$ctrl.dimensions = {
				mobile: {
					mediaQuery: '(min-width: 1px)',
					mediaQueryRetina: '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1px) and (min--moz-device-pixel-ratio: 2),(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1px) and (min-device-pixel-ratio: 2),(min-width: 1px) and (min-resolution: 192dpi),(min-width: 1px) and (min-resolution: 2dppx)',
					width: $ctrl.mobileWidth,
					height: $ctrl.mobileHeight
				},
				tablet: {
					mediaQuery: '(min-width: 768px)',
					mediaQueryRetina: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 768px) and (min--moz-device-pixel-ratio: 2),(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 768px) and (min-device-pixel-ratio: 2),(min-width: 768px) and (min-resolution: 192dpi),(min-width: 768px) and (min-resolution: 2dppx)',
					width: $ctrl.desktopWidth,
					height: $ctrl.desktopHeight
				},
				desktop: {
					mediaQuery: '(min-width: 1025px)',
					mediaQueryRetina: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1025px) and (min-device-pixel-ratio: 2),(min-width: 1025px) and (min-resolution: 192dpi),(min-width: 1025px) and (min-resolution: 2dppx)',
					width: $ctrl.desktopWidth,
					height: $ctrl.desktopHeight
				}
			};

			// Upon receiving an updated package, refresh the jelly if it's flagged as an iris-jelly
			// and if it's initial acode matches the one received by the event
			$scope.$on('packages-selector:selected-package-updated', (_, data) => {
				if ($ctrl.mfgCode) {
					if ($ctrl.packageCodeOverride === "") {
						$ctrl.sa = $ctrl.mfgCode + "," + data.package.code.substr(0, 1) + $ctrl.cposVehicleType + data.package.code.substr(2, 1) + "," + data.package.code;
					}
					$ctrl.generateUrls();
				} else {
					$ctrl.updateTrimInformation(data.package.code);
				}
			});

			$scope.$on('packages-selector:selected-trim-updated', (_, data) => {
				$ctrl.trim = data;
				$ctrl.updateTrimInformation();
			});

			$scope.$on('options-selector:tone-updated', (_, data) => {
				$ctrl.tone = data.tone.substr(0, 3);
				$ctrl.generateUrls();
			});

			$scope.$on('options-selector:paint-updated', (_, data) => {
				$ctrl.paint = data.paint.substr(0, 3);
				$ctrl.generateUrls();
			});

			$scope.$on('options-selector:secondary-paint-updated', (_, data) => {
				$ctrl.secondaryPaint = data.paint.substr(0, 3);
				$ctrl.generateUrls();
			});

			$scope.$on('options-selector:wheels-updated', (_, data) => {
				if ($ctrl.hasWheels) {
					$ctrl.wheels = data.wheels;
					$ctrl.generateUrls();
				}
			});

			$scope.$on('options-selector:fabric-updated', (_, data) => {
				$ctrl.fabric = data.fabric.substr(0, 2);
				$ctrl.dirty360Viewer = true;
				$ctrl.generateUrls();
				if ($ctrl.PSV && $ctrl.show360Viewer) {
					$ctrl.init360();
				}
			});

			$scope.$on('option-selector:sa-updated', (_, data) => {
				if ($ctrl.sa) {
					$ctrl.sa = $ctrl.sa + "," + data.code;
					$ctrl.generateUrls();
				}
				if ($ctrl.PSV && $ctrl.show360Viewer) {
					$ctrl.init360();
				}
			});

			$ctrl.image = $element.find("#jelly-img");
		};

		$ctrl.init360 = () => {
			if (!$ctrl.centerPanoURL || !$ctrl.has360) {
				return;
			}
			$ctrl.jellyLoading = true;
			$scope.isDirty = true;
			if ($ctrl.PSV) {
				$ctrl.PSV.destroy();
			}

			$ctrl.imageSize = { width: $ctrl.image.width(), height: $ctrl.image.height() };

			$ctrl.photosphereElement = $element.find('#photosphere-' + $ctrl.jellyId).get(0);
			$ctrl.PSV = new PhotoSphereViewer({
				panorama: $ctrl.centerPanoURL,
				loading_img: $ctrl.loadingImage,
				container: $ctrl.photosphereElement,
				gyroscope: true,
				mousecursor: true,
				move_speed: 1,
				min_fov: 60,
				max_fov: 60,
				default_fov: 60,
				navbar: [
					'fullscreen',
					{
						id: 'close-psv-button',
						className: 'close-psv-button',
						onClick: function () {
							$ctrl.toggle360Viewer();
						}
					}
				],
				size: {
					height: $ctrl.imageSize.height,
					width: $ctrl.imageSize.width,
				}
			});

			$ctrl.PSV.on('ready', function () {
				$ctrl.dirty360Viewer = false;
				$ctrl.show360Viewer = true;
				$scope.$digest();
				$window.dispatchEvent(new Event('resize'));
				$ctrl.PSV.zoom(0);
				$ctrl.PSV.stopAutorotate();
				$ctrl.jellyLoading = false;
				$scope.isDirty = true;
				$ctrl.update360NavbarStyle();
			});

			$ctrl.PSV.on('fullscreen-updated', function () {
				$ctrl.PSV.zoom(0);
				$ctrl.PSV.stopAutorotate();
				$window.dispatchEvent(new Event('resize'));

				$('.psv-fullscreen-button .psv-button-svg').attr('tabindex','0');

				$('.psv-fullscreen-button .psv-button-svg').keydown(function(event) {
					if (event.keyCode == 13) {
						$ctrl.PSV.enterFullscreen();
					}
				});
				$('.close-psv-button').attr('tabindex','0');
				$('.close-psv-button').keydown(function(event) {
					if (event.keyCode == 13) {
						$ctrl.toggle360Viewer();
					}
				});
			});
		};

		// These elements are being added to the DOM by the PhotoSphereViewer library.
		// Since we want to change their style we have no choice but to use this hacky method.
		// It gets called when a PhotoSphereViewer triggers a "ready" event.
		$ctrl.update360NavbarStyle = () => {
			const navbarContainers = document.getElementsByClassName("psv-container psv-container--has-navbar");
			const navbars = document.getElementsByClassName("psv-navbar psv-navbar--open");
			const fullscreenButtons = document.getElementsByClassName("psv-button psv-button--hover-scale psv-fullscreen-button");
			const closePsvButtons = document.getElementsByClassName("close-psv-button");
			const arrow_keys_handler = function(e) {
				switch(e.keyCode) {
					case 37: case 39: case 38: case 40: e.preventDefault(); break;
					default: break;
				}
			};

			$('.psv-canvas').attr('tabindex','0');
			$('.psv-canvas').focus();
			$ctrl.PSV.startKeyboardControl();
			window.addEventListener('keydown',arrow_keys_handler, false);

			$('.close-psv-button').attr('tabindex','0');
			$('.close-psv-button').keydown(function(event) {
				if (event.keyCode == 13) {
					$ctrl.toggle360Viewer();
					window.removeEventListener('keydown',arrow_keys_handler, false);
				}
			});
			$('.psv-canvas-container').attr('role','img');
			$('.psv-canvas-container').attr('aria-label',$ctrl.jellyThreeSixty);

			$('.psv-canvas').focus(function(e) {
				$ctrl.PSV.startKeyboardControl();
				window.addEventListener('keydown',arrow_keys_handler, false);
			});
			$('.psv-canvas').blur(function() {
				$ctrl.PSV.stopKeyboardControl();
				window.removeEventListener('keydown',arrow_keys_handler, false);
			});
			$('.psv-fullscreen-button .psv-button-svg').attr('tabindex','0');
			$('.psv-fullscreen-button .psv-button-svg').keydown(function(event) {
				if (event.keyCode == 13) {
					$ctrl.PSV.enterFullscreen();
				}
			});

			for (let i = 0; i < navbarContainers.length; i++) {
				navbarContainers[i].style.display = "flex";
				navbarContainers[i].style.alignItems = "center";
			}

			for (let i = 0; i < navbars.length; i++) {
				navbars[i].style.background = "none";
				navbars[i].style.justifyContent = "flex-end";
				navbars[i].style.position = "static";
				navbars[i].style.pointerEvents = "none";
				navbars[i].style.flexDirection = "column";
				navbars[i].style.alignItems = "flex-end";
			}

			for (let i = 0; i < fullscreenButtons.length; i++) {
				fullscreenButtons[i].style.background = "rgba(206,206,206,0.85)";
				fullscreenButtons[i].style.borderRadius = "3px 3px 0 0";
				fullscreenButtons[i].style.marginRight = "10px";
				fullscreenButtons[i].style.pointerEvents = "all";
			}

			for (let i = 0; i < fullscreenButtons.length; i++) {
				closePsvButtons[i].style.background = "rgba(152,152,152,0.85)";
				closePsvButtons[i].style.borderRadius = "0 0 3px 3px";
				closePsvButtons[i].style.marginRight = "10px";
				closePsvButtons[i].style.pointerEvents = "all";
				if (closePsvButtons[i].childElementCount < 1) {
					let iconElement = document.createElement('i');
					iconElement.className = "fcaicon fcaicon-360";
					closePsvButtons[i].appendChild(iconElement);
				}
			}

		}

		$ctrl.toggle360Viewer = () => {
			if ($ctrl.dirty360Viewer) {
				$ctrl.init360();
			} else {
				$ctrl.show360Viewer = !$ctrl.show360Viewer;
				// Hacky solution for the resize event to happen after the page has finished
				// rendering the changes from the toggle.
				// And the resize is a hack in itself to have the PSV re-render.
				setTimeout(() => {
					$window.dispatchEvent(new Event('resize'));
				}, 200);
			}
		}

		$ctrl.$postLink = () => {
			$ctrl.jellyLoading = true;

			if ($ctrl.image) {
				$ctrl.image.get(0).onload = $ctrl.onImageLoaded.bind(this);
				$ctrl.image.get(0).onerror = $ctrl.onImageLoadError.bind(this);
			}
		}

		$ctrl.handleDrag = () => {
			// Rotate jelly if the user drags it for more than the set threshold
			$(`#${$ctrl.jellyId}`)
				.mousedown(function (e) {
					$ctrl.isDragging = true;
					$ctrl.cursorPosition = e.pageX;
				})
				.mousemove(function (e) {
					if (!$ctrl.isDragging) {
						return;
					}

					const newCursorPos = e.pageX;
					const delta = $ctrl.cursorPosition - newCursorPos;
					const abs = Math.abs;

					if (abs(delta) > dragThreshold) {
						delta > 0 ? $ctrl.rotatePositive() : $ctrl.rotateNegative();
						$ctrl.isDragging = false;
					}
				})
				.mouseup(function (e) {
					$ctrl.isDragging = false;
				});
		};

		$ctrl.updateTrimInformation = (pkgCode=null) => {
			if (!$ctrl.trim) {
				return;
			}

			const trimGroup = $ctrl.trim.selectedTrimGroup;
			const trim = $ctrl.trim.selectedTrim;

			const irisOptions = trimGroup.trimIrisJellyOptions;
			$ctrl.cposVehicleType = irisOptions.cposVehicleType;
			$ctrl.povControlsVisible = $ctrl.showPovControls && irisOptions.hasInteriorImage;
			$ctrl.has360 = irisOptions.has360 || false;
			$ctrl.mfgCode = trim.mfgCode;
			let packageCode = pkgCode || trim.packages[0].code;

			if (irisOptions.packageCodeOverride) {
				$ctrl.packageCodeOverride = irisOptions.packageCodeOverride;
				$ctrl.sa = $ctrl.mfgCode + "," + $ctrl.generateSaAttribute($ctrl.packageCodeOverride, $ctrl.cposVehicleType);
			} else {
				$ctrl.packageCodeOverride = "";
				$ctrl.sa = $ctrl.mfgCode + "," + $ctrl.generateSaAttribute(packageCode, $ctrl.cposVehicleType);
			}

			$ctrl.vehicle = $ctrl.year + "_" + trim.mfgCode.substr(0, 2);

			$ctrl.generateUrls();
		};

		$ctrl.makeSaParameters = () => {
			return $ctrl.jellyAdditionalOptions ? $ctrl.sa + ',' + $ctrl.jellyAdditionalOptions : $ctrl.sa;
		}

		$ctrl.generateUrls = () => {
			if ($ctrl.paint === "") {
				return;
			}

			// Construct the Iris url for the jelly.
			// x value is set to 300 instead of the default 500 to center the image.
			let url = $ctrl.irisRootURl + "&brand=" + $ctrl.brand + "&vehicle=" + $ctrl.vehicle + "&paint=" + $ctrl.paint +
				"&sa=" + $ctrl.makeSaParameters() + ',' + ($ctrl.wheels ? $ctrl.wheels : '') + ',' + $ctrl.tone + ',' + $ctrl.secondaryPaint + "&pov=" + $ctrl.pov + "&fabric=" + $ctrl.fabric;

			// Required to update the meta property of the jelly
			$ctrl.baseJellyUrl = url;

			// Wipe the urls initially in case of previous requests
			$ctrl.previousUrls = $ctrl.urls;
			$ctrl.urls = "";

			$ctrl.image.get(0).removeAttribute('style');
			$ctrl.urls = $ctrl.generateUrlsForPov($ctrl.pov);
			if ($ctrl.urls !== $ctrl.previousUrls) {
				$ctrl.jellyLoading = true;
			}
			$rootScope.$broadcast('jelly:updated-jelly', {
				'jellyUrl': $ctrl.irisRootURl +
					"&brand=" + $ctrl.brand +
					"&vehicle=" + $ctrl.vehicle +
					"&paint=" + $ctrl.paint +
					"&sa=" + $ctrl.makeSaParameters() + ',' +
					$ctrl.tone + ',' + $ctrl.secondaryPaint +
					"&fabric=" + $ctrl.fabric +
					"&pov=fronthero&width=500&height=255&x=1000&w=7000&y=3000&h=3100&bkgnd=white"
			});
			$ctrl.generateUrlFor360();
			$scope.isDirty = true;

			if ($ctrl.povMode === 'E') {
				$rootScope.$broadcast('jelly:exterior-jelly', $ctrl.jellyUrl);
			}
		};

		$ctrl.generateUrlFor360 = () => {
			$ctrl.centerPanoURL = $ctrl.irisRootURl + "&brand=" + $ctrl.brand + "&vehicle=" + $ctrl.vehicle + "&paint=" + $ctrl.paint +
				"&sa=" + $ctrl.makeSaParameters() + ',' + $ctrl.tone + ',' + "&pov=centerpano" + "&fabric=" + $ctrl.fabric;
		}

		$ctrl.generateUrlsForPov = () => {
			// Construct the Iris url for the jelly.
			// x value is set to 300 instead of the default 500 to center the image.
			let url = $ctrl.irisRootURl + "&brand=" + $ctrl.brand + "&vehicle=" + $ctrl.vehicle + "&paint=" + $ctrl.paint +
				"&sa=" + $ctrl.makeSaParameters() + ',' + ($ctrl.wheels ? $ctrl.wheels : '') + ',' + $ctrl.tone + ',' + $ctrl.secondaryPaint + "&pov=" + $ctrl.pov + "&fabric=" + $ctrl.fabric;

			let result = '';

			//Mobile
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.mobile.mediaQuery, url,
				$ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 1) + "'],";

			//Mobile retina
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.mobile.mediaQueryRetina, url,
				$ctrl.dimensions.mobile.width, $ctrl.dimensions.mobile.height, $ctrl.jellyParameters, 2) + "'],";

			//Tablet
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.tablet.mediaQuery, url,
				$ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 1) + "'],";

			//Tablet retina
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.tablet.mediaQueryRetina, url,
				$ctrl.dimensions.tablet.width, $ctrl.dimensions.tablet.height, $ctrl.jellyParameters, 2) + "'],";

			//Desktop
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.desktop.mediaQuery, url,
				$ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 1) + "'],";

			//we save jelly url in config service to not recalculate it if the configuration is saved
			configService.saveIrisJelly($ctrl.generateSingleIrisUrl(url, $ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 1));

			//Desktop retina
			result += "['" + $ctrl.generateSingleIrisObj($ctrl.dimensions.desktop.mediaQueryRetina, url,
				$ctrl.dimensions.desktop.width, $ctrl.dimensions.desktop.height, $ctrl.jellyParameters, 2) + "']";

			$ctrl.jellyUrl = $ctrl.generateSingleIrisUrl(url, 1920, undefined, $ctrl.pov[0] === 'E' ? $ctrl.jellyParameters : '', 1);
			return result;
		};

		$ctrl.generateSingleIrisObj = (mediaQuery, baseUrl, width, height, parameters, pixelDensity) => {
			return mediaQuery + "', '" + $ctrl.generateSingleIrisUrl(baseUrl, width, height, parameters, pixelDensity);
		};

		$ctrl.generateSingleIrisUrl = (baseUrl, width, height, parameters, pixelDensity) => {
			let url = baseUrl;
			url += "&width=" + (width * pixelDensity);

			if (height !== undefined) {
				url += "&height=" + (height * pixelDensity);
			}

			if (parameters !== undefined && parameters) {
				url += "&" + parameters;
			}

			url += "&bkgnd=" + $ctrl.background;

			return url;
		};

		$ctrl.onImageLoaded = () => {
			$scope.$apply(() => {
				$ctrl.jellyLoading = false;
				$ctrl.jellyLoaded = true;
			});

			$ctrl.setSpinControlsEnabledValue(false);

			$ctrl.currentRetryAttempt = 0;
		};

		$ctrl.onImageLoadError = () => {
			if ($ctrl.currentRetryAttempt >= MAX_RETRY) {
				return;
			}

			++$ctrl.currentRetryAttempt;

			$ctrl.updateTrimInformation();
		};

		$ctrl.generateSaAttribute = (packageCode, cposVehicleType) => {
			return packageCode.substr(0, 1) + cposVehicleType + packageCode.substr(2, 1) + "," + packageCode;
		};

		$ctrl.rotateNegative = () => {
			if ($ctrl.previousDisabled) {
				return;
			}

			$ctrl.jellyLoading = true;

			$ctrl.setSpinControlsEnabledValue(false);
			$ctrl.pov = $ctrl.getPreviousPov();
			$ctrl.generateUrls();
		};

		$ctrl.rotatePositive = () => {
			if ($ctrl.nextDisabled) {
				return;
			}

			$ctrl.jellyLoading = true;

			$ctrl.setSpinControlsEnabledValue(false);
			$ctrl.pov = $ctrl.getNextPov();
			$ctrl.generateUrls();
		};

		$ctrl.setSpinControlsEnabledValue = value => {
			$ctrl.previousDisabled = $ctrl.nextDisabled = value;
		};

		$ctrl.getPreviousPov = () => {
			const povNumber = parseInt($ctrl.pov.substring(1));
			const step = Math.floor(maxNumberOfAngles / $ctrl.numberOfAngles);

			if (povNumber - step < 1) {
				return $ctrl.povMode + maxNumberOfAngles.toString();
			} else {
				return $ctrl.povMode + ("0" + (povNumber - step)).slice(-2);
			}
		};

		$ctrl.getNextPov = () => {
			let povNumber = parseInt($ctrl.pov.substring(1));
			const step = Math.floor(maxNumberOfAngles / $ctrl.numberOfAngles);

			if (povNumber + step > maxNumberOfAngles) {
				return $ctrl.povMode + minAngle;
			} else {
				return $ctrl.povMode + ("0" + (povNumber + step)).slice(-2);
			}
		};

		$ctrl.changePovMode = newMode => {
			if (newMode === 'interior') {
				$ctrl.povMode = 'I';
				$ctrl.showSpinControls = false;
				$ctrl.showGradient = "hide-gradient";
				$ctrl.pov = $ctrl.povMode + minAngle;
			} else if (newMode === 'exterior') {
				$ctrl.povMode = 'E';
				$ctrl.showSpinControls = true;
				$ctrl.showGradient = "";
				$ctrl.pov = $ctrl.povMode + startingAngle;
			}
			if (!$ctrl.show360Viewer) {
				$ctrl.jellyLoading = true;
			}
			$ctrl.callback({ newPov: newMode });
			$ctrl.generateUrls();
		};

		$ctrl.colourWheelControlPressed = (newMode) => {
			if (newMode === 1) {
				$rootScope.$broadcast('colours-wheels:paint-chips-updated', {mode: 'colours'});
				$ctrl.currentTab = 'colors';
			} else if (newMode === 2) {
				$rootScope.$broadcast('colours-wheels:paint-chips-updated', {mode: 'wheels'});
				// When changing to wheel selection the jelly should rotate to the side view.
				$ctrl.currentTab = 'wheels';
			}

			$ctrl.pov = sideViewPov;
			$ctrl.generateUrls();

			$ctrl.callback({ newPov: newMode });
		};

		$ctrl.getIrisBrandByBrandCode = (brandCode) => {
			var result = "";
			if (brandCode) {
				result = config.getConfig('irisCodeForBrand')[brandCode];
			}
			return result;
		};

		$ctrl.getAnalyticsId = () => {
			if ($ctrl.currentTab === "wheels") {
				return "build-price-wheels-rotate-";
			} else {
				return "build-price-colour-rotate-";
			}
		};

		$ctrl.getTabActive = () => {
			if ($ctrl.currentTab === "wheels") {
				return 'wheels';
			} else {
				return 'colors';
			}
		};

		$ctrl.generateAltTag = () => {
			let altText = '';
			if($ctrl.selectedNameplateName) {
				altText += ' ' + $ctrl.selectedNameplateName;
			}
			if($ctrl.trim && $ctrl.trim.selectedTrim) {
				altText += ' ' + $ctrl.trim.selectedTrim.trimName;
			}
			return altText;
		}
	}
})();
