(function() {
    angular
    .module('fca.sni.compareVehicleFooter')
    .component('sniCompareVehicleFooter',{
        controller: SniCompareVehicleFooter,
        controllerAs: '$ctrl',
        templateUrl: '/sni/compare-vehicle-footer/compare-vehicle-footer.html',
        bindings: {
            nameplateClick: '&',
            neutralColors: '<',
            sniDetailsUrl: '@',
            viewport: '<',
            vehicles: '<',
            sniEshopUrl: '@',
            sniEshopActivated: '@'
        }
    });
    
    function SniCompareVehicleFooter($translate, $timeout, fcaSniCompareDetailsUrl) {
        'ngInject';
        const $ctrl = this;

        $ctrl.language = window.FCA_SITES_CONFIG.language;

        $ctrl.getDetailsUrl = (vehicle) => {
            return fcaSniCompareDetailsUrl.getUrl(vehicle, $ctrl.sniDetailsUrl, $ctrl.sniEshopActivated, $ctrl.sniEshopUrl, getStoredAffiliateId());
        }

        $ctrl.$postLink = () => {
            new Waypoint.Sticky({
                direction: 'up',
                element: $('.C_CVF-holder')[0],
                offset: 'bottom-in-view'
            });
        }
        // Sorry about the timeout, but it seems like the only way to make it behave correctly
        setTimeout(() => {
            Waypoint.refreshAll()
        }, 500);

        $ctrl.displayedVehicles = () => {
            const visibleIndices = $ctrl.viewport.visibleIndices();
            
            const numVehicles = Math.min(
                visibleIndices.length,
                $ctrl.vehicles.length
            );

            const vehicles = []
            for (let i = 0; i < numVehicles; i++) {
                const vehicle = $ctrl.vehicles[visibleIndices[i]];
                if (vehicle) vehicles.push(vehicle);
            }

            return vehicles;
        }

        $ctrl.onDotClick = index => $ctrl.nameplateClick({ index });

        $ctrl.comparingLabel = () => {
            if (!$ctrl.vehicles) {
                return;
            }

            const number = $ctrl.vehicles.length;
            return $translate.instant(
                'sni.list.page.compare.comparingNumber',
                { number }
            );
        }
    }
})();