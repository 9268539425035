(function() {
    angular
        .module('fca.components.formVehicleSelector', ['ngMessages'])
        .component('componentsFormVehicleSelector', {
            controller: ComponentsFormVehicleSelectorController,
            controllerAs: '$fVSCtrl',
            templateUrl: '/components/form-vehicle-selector/form-vehicle-selector.html',
            bindings: {
                apiUrl: '@',
                brandCode: "@",
                isApplyForFinancing: "<",
                jellyPov: "@",
                jellyParameters: "@",
                jellyMobileWidth: "@",
                location: "<",
                jellyMobileHeight: "@",
                skipJellyDisplay: "<?"
            }
        });

    function ComponentsFormVehicleSelectorController($scope, $rootScope, $http, $timeout) {
        'ngInject';

        const $fVSCtrl = this;

        $fVSCtrl.vehicleObject = [];
        $fVSCtrl.language = FCA_SITES_CONFIG.language;

        $fVSCtrl.brands = [];
        $fVSCtrl.currentBrand = [];
        $fVSCtrl.brand = ``;
        $fVSCtrl.brandCode = "";
        $fVSCtrl.currentBrandCode = "";
        $fVSCtrl.currentSubBrandCode = "";

        $fVSCtrl.nameplatesYears = {};
        $fVSCtrl.combinedNamePlatesYears = [];
        $fVSCtrl.nameplateCode = "";
        $fVSCtrl.nameplateYear = ``;
        $fVSCtrl.nameplate = ``;
        $fVSCtrl.year = "";
        $fVSCtrl.basePrice = 0;

        $fVSCtrl.trimGroups = {};
        $fVSCtrl.trimGroup = ``;
        $fVSCtrl.trimGroupCode = "";
        $fVSCtrl.modelYearId = "";
        $fVSCtrl.fullVehicle = "";
        $fVSCtrl.selectedTrim = "";
        $fVSCtrl.acode = "";
        $fVSCtrl.provinceCode = "";
        $fVSCtrl.packageAndOptionsCode = "";
        $fVSCtrl.packageAndOptionsCodeArray = [];
        $fVSCtrl.vin = "";

        $fVSCtrl.objJellyTrimGroupUrl = {};
        $fVSCtrl.jellyUrl = "";
        $fVSCtrl.legacyJellyUrls = [];
        $fVSCtrl.allJellyUrls = "";
        $fVSCtrl.rootIrisUrl = FCA_SITES_CONFIG.irisJellyBaseUrl + "/mediaserver/iris?client=FCAUS&market=U";
        $fVSCtrl.legacyRootURl = FCA_SITES_CONFIG.jelliesUrl.replace('jellies/renditions/','');
        $fVSCtrl.vehicleType = "";
        $fVSCtrl.paint = "";
        $fVSCtrl.sa = "";
        $fVSCtrl.vehicle = "";
        $fVSCtrl.mfgCode = "";
        $fVSCtrl.jellyBrandCode = "";
        $fVSCtrl.jellyType = "";

        $fVSCtrl.brandSelectField = "brandSelector";
        $fVSCtrl.nameplateSelectField = "nameplateSelector";
        $fVSCtrl.trimGroupSelectField = "trimGroupSelector";
        $fVSCtrl.vehicleTileField = "vehicleCardTile";

        $fVSCtrl.visibleSelectField = '';

        $fVSCtrl.hideNameplateSelector = false;
        $fVSCtrl.hideTrimGroupSelector = false;

        $fVSCtrl.isBuildAndPriceMode = false;
        $fVSCtrl.isSniMode = false;
        $fVSCtrl.isDaaMode = false;

        $fVSCtrl.pricingObject = [];
        $fVSCtrl.packageObject = {}; //this is in fact the trimgroups object of the trim selector data

        $fVSCtrl.hasTrimGroupPackage = false;

        $fVSCtrl.$onInit = () => {
            $fVSCtrl.loading('.C_FVS-loader');
            $fVSCtrl.getVehicleSelectorData().then((r) => {
                if($fVSCtrl.brandCode == '' || $fVSCtrl.brandCode == null) {
                    $fVSCtrl.brandCode = FCA_SITES_CONFIG.name;
                } else {
                    $fVSCtrl.currentBrandCode = FCA_SITES_CONFIG.subBrandCode || $fVSCtrl.brandCode;
                };
                $fVSCtrl.setCurrentBrandCode($fVSCtrl.currentBrandCode);
                $fVSCtrl.showNamePlates($fVSCtrl.brandCode);
                $fVSCtrl.getBrandName($fVSCtrl.brandCode);
                if($fVSCtrl.isBuildAndPriceMode) {
                    $fVSCtrl.getNameplateNameFromCode($fVSCtrl.nameplateCode);
                }
                $rootScope.$broadcast('form-vehicle-selector:nameplate-name-and-brand-name', {
                    nameplateName: $fVSCtrl.nameplate,
                    brandName: $fVSCtrl.brand
                });
                if($fVSCtrl.isDaaMode) {
                    $fVSCtrl.getDAAVehicleData();
                }
            });
            $fVSCtrl.loaded('.C_FVS-loader');
            $rootScope.$on('lead-form:standalone-vehicle-info', (event, data) => {
                $fVSCtrl.changeVisibleSelectField($fVSCtrl.nameplateSelectField);
                $fVSCtrl.loaded('.C_FVS-loader');
            });

            $rootScope.$on('lead-form:sni-vehicle-info', (event,data) => {
                if(data.jellies && data.jellies[0]) {
                    $fVSCtrl.jellyUrl = $fVSCtrl.legacyRootURl + data.jellies[0].path;
                }

                $fVSCtrl.legacyJellyUrls = data.jellies;
                $fVSCtrl.nameplateCode = data.nameplateCode;
                $fVSCtrl.year = data.year;
                $fVSCtrl.nameplate = data.nameplateName[$fVSCtrl.language];
                $fVSCtrl.trimGroup = data.trimGroupName[$fVSCtrl.language];
                $fVSCtrl.vin = data.vin;
                $fVSCtrl.isSniMode = true;
                $rootScope.$$postDigest(function() {
                    $fVSCtrl.loaded('.C_FVS-loader');
                    $fVSCtrl.changeVisibleSelectField($fVSCtrl.vehicleTileField);
                    $fVSCtrl.checkIfJellyIsLoading();
                });
            });

            $rootScope.$$postDigest(function() {
                $rootScope.$on('lead-form:build-and-price-vehicle-info', (event,data) => {
                    $fVSCtrl.brandCode = data.brandCode;
                    $fVSCtrl.nameplateCode = data.nameplate;
                    $fVSCtrl.trimGroup = data.trimName;
                    $fVSCtrl.year = data.year;
                    $fVSCtrl.modelYearId = data.modelYearId;
                    $fVSCtrl.isBuildAndPriceMode = true;
                    $fVSCtrl.getVehicleJelly($fVSCtrl.brandCode, $fVSCtrl.nameplateCode, $fVSCtrl.year, $fVSCtrl.modelYearId);
                    $fVSCtrl.loaded('.C_FVS-loader');
                    $fVSCtrl.changeVisibleSelectField($fVSCtrl.vehicleTileField);
                    $fVSCtrl.checkIfJellyIsLoading();
                });
                $rootScope.$on('lead-form:daa-vehicle-info', (event,data) => {
                    $fVSCtrl.nameplateCode = data.nameplate;
                    $fVSCtrl.modelYearId = data.modelYearId;
                    $fVSCtrl.acode = data.acode;
                    $fVSCtrl.isDaaMode = true;
                    $fVSCtrl.changeVisibleSelectField($fVSCtrl.vehicleTileField);
                    $fVSCtrl.loaded('.C_FVS-loader');
                });
                $rootScope.$emit('initiateVehicleSelectorEvent', null);
            });

            $fVSCtrl.dimensions = {
                mobile: {
                    mediaQuery: '(min-width: 1px)',
                    mediaQueryRetina: '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1px) and (min--moz-device-pixel-ratio: 2),(min-width: 1px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1px) and (min-device-pixel-ratio: 2),(min-width: 1px) and (min-resolution: 192dpi),(min-width: 1px) and (min-resolution: 2dppx)',
                    width: $fVSCtrl.mobileWidth,
                    height: $fVSCtrl.mobileHeight
                },
                tablet: {
                    mediaQuery: '(min-width: 768px)',
                    mediaQueryRetina: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 768px) and (min--moz-device-pixel-ratio: 2),(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 768px) and (min-device-pixel-ratio: 2),(min-width: 768px) and (min-resolution: 192dpi),(min-width: 768px) and (min-resolution: 2dppx)',
                    width: $fVSCtrl.desktopWidth,
                    height: $fVSCtrl.desktopHeight
                },
                desktop: {
                    mediaQuery: '(min-width: 1025px)',
                    mediaQueryRetina: '(min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2),(min-width: 1025px) and (min--moz-device-pixel-ratio: 2),(min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1),(min-width: 1025px) and (min-device-pixel-ratio: 2),(min-width: 1025px) and (min-resolution: 192dpi),(min-width: 1025px) and (min-resolution: 2dppx)',
                    width: $fVSCtrl.desktopWidth,
                    height: $fVSCtrl.desktopHeight
                }
            };
            $fVSCtrl.background = "white";
        };

        $fVSCtrl.getDAAVehicleData = () => {
            const daaVehicle = $fVSCtrl.combinedNamePlatesYears.find(element => element.modelYearId === $fVSCtrl.modelYearId);
            $fVSCtrl.year = daaVehicle.nameplateYear;
            $fVSCtrl.nameplate = daaVehicle.nameplateName;
            const packageCodeApiUrl =
                `/api/buildandprice/${$fVSCtrl.language}`+
                `/brand/${$fVSCtrl.brandCode}`+
                `/nameplate/${$fVSCtrl.nameplateCode}`+
                `/year/${$fVSCtrl.year}`+
                `/modelyear/${$fVSCtrl.modelYearId}/trims`;

            $fVSCtrl.getVehicleJelly($fVSCtrl.brandCode, $fVSCtrl.nameplateCode, $fVSCtrl.year, $fVSCtrl.modelYearId);

            $http.get(packageCodeApiUrl).then(packageResult => {
                $fVSCtrl.packageObject = packageResult.data.trimGroups;
                $fVSCtrl.packageObject.forEach(trimgroup => {
                    trimgroup.trims.forEach(trim => {
                        if(trim.acode === $fVSCtrl.acode) {
                            $fVSCtrl.trimGroup = trimgroup.code;
                        }
                    })
                });
                $fVSCtrl.getCalculatorParameters();
            }).catch(packageError => {
                console.error('Something went wrong : ', packageError);
            });
        };

        $fVSCtrl.getVehicleSelectorData = () => {
            return $http.get(`/api/lead/${$fVSCtrl.language}/vehicle-selector`).then((result) => {
                $fVSCtrl.vehicleObject = result.data;
                for(let intBrands = 0; intBrands < $fVSCtrl.vehicleObject.length; intBrands++) {
                    // Loops through all the brands to store them inside the brands object
                    let arrBrands = $fVSCtrl.vehicleObject[intBrands];
                    $fVSCtrl.brands[intBrands] = {
                        "brandCode": arrBrands.code,
                        "subBrandCode": arrBrands.subBrandCode || "",
                        "brandName": arrBrands.name,
                        "nameplates": arrBrands.children
                    };
                }
            });
        };

        $fVSCtrl.showNamePlates = (brandValue) => {
            let nameplates = $fVSCtrl.brands.filter((element) => element.brandCode === brandValue);
            nameplates = $fVSCtrl.getCurrentBrand(nameplates);
            let arrNameplates = {};
            let arrCombinedNameplates = {};
            $fVSCtrl.nameplatesYears = nameplates[0].nameplates;
            for(let i1 = 0; i1 < $fVSCtrl.nameplatesYears.length; i1++) {
                // Loops through all the nameplates and creates an array
                arrNameplates[i1] = {
                    "code": $fVSCtrl.nameplatesYears[i1].code,
                    "name": $fVSCtrl.nameplatesYears[i1].name,
                    "years": $fVSCtrl.nameplatesYears[i1].children
                };
                for(let i2 = 0; i2 < arrNameplates[i1].years.length; i2++) {
                    // Loops through the previous array to combine each nameplate with each year
                    if(arrNameplates[i1].years[i2].children.length) {
                        arrCombinedNameplates = {
                            "nameplateCode": arrNameplates[i1].code,
                            "nameplateName": arrNameplates[i1].name,
                            "nameplateYear": arrNameplates[i1].years[i2].code,
                            "trimGroups": arrNameplates[i1].years[i2].children,
                            "modelYearId": arrNameplates[i1].years[i2].autodataModelYearId
                        };
                        if($fVSCtrl.language === 'en') {
                            arrCombinedNameplates.fullNamePlate = arrNameplates[i1].years[i2].name;
                        } else if($fVSCtrl.language === 'fr') {
                            arrCombinedNameplates.fullNamePlate = arrNameplates[i1].years[i2].name;
                        }
                        $fVSCtrl.combinedNamePlatesYears.push(arrCombinedNameplates);
                    }
                }
            }
        };

		$fVSCtrl.getVehicleJelly = (argBrandCode, argNameplateCode, argYear, argModelYearId) => {
			let brandCode = argBrandCode;
			let year = argYear;

			$fVSCtrl.objJellyTrimGroupUrl = $fVSCtrl.getSelectedPackageData();

			if($fVSCtrl.objJellyTrimGroupUrl.legacyJellyName) {
				const jelliesMobile = $fVSCtrl.getSelectedPackageData().jellies.filter(jelly => jelly.resolutionCode === 'MOBILE');
				$fVSCtrl.jellyUrl = jelliesMobile.length > 0 ? `${$fVSCtrl.legacyRootURl}${jelliesMobile[0].path}` : "";
			} else if($fVSCtrl.objJellyTrimGroupUrl.jellyRenderingType === 'iris') {
				$fVSCtrl.jellyType = 'iris';
				$fVSCtrl.vehicleType = $fVSCtrl.objJellyTrimGroupUrl.hasOwnProperty('cposVehicleType') ? $ctrl.objJellyTrimGroupUrl.trimIrisJellyOptions.cposVehicleType : '';
				$fVSCtrl.paint = $fVSCtrl.objJellyTrimGroupUrl.trims[0].primaryColourCode;
				$fVSCtrl.mfgCode = $fVSCtrl.objJellyTrimGroupUrl.trims[0].mfgCode;
				$fVSCtrl.jellyBrandCode = window.FCA_SITES_CONFIG.irisCodeForBrand[brandCode];
				$fVSCtrl.vehicle = year + "_" + $fVSCtrl.mfgCode.substr(0,2);

				if ($fVSCtrl.vehicleType !== '') {
					$fVSCtrl.sa =
						$fVSCtrl.mfgCode + "," +
						$fVSCtrl.packageAndOptionsCode.substr(0,1) +
						$fVSCtrl.vehicleType + $fVSCtrl.packageAndOptionsCode.substr(2,1)+ "," +
						$fVSCtrl.packageAndOptionsCode;
				} else {
					$fVSCtrl.sa =
						$fVSCtrl.mfgCode + "," +
						$fVSCtrl.packageAndOptionsCode.substr(0,1) +
						"D" + $fVSCtrl.packageAndOptionsCode.substr(2,1)+ "," +
						$fVSCtrl.packageAndOptionsCode;
				}

				$fVSCtrl.jellyUrl =
					`${$fVSCtrl.rootIrisUrl}&brand=${$fVSCtrl.jellyBrandCode}`+
					`&vehicle=${$fVSCtrl.vehicle}&paint=${$fVSCtrl.paint}&sa=${$fVSCtrl.sa}`+
					`&pov=${$fVSCtrl.jellyPov}&width=${$fVSCtrl.jellyMobileWidth}&height=${$fVSCtrl.jellyMobileHeight}`+
					`&${$fVSCtrl.jellyParameters}&bkgnd=${$fVSCtrl.background}`;

				$fVSCtrl.allJellyUrls = "";

				// Mobile
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.mobile.mediaQuery. url,
					$fVSCtrl.dimensions.mobile.width, $fVSCtrl.dimensions.mobile.height, $fVSCtrl.jellyParameters, 1) + "']";
				// Mobile Retina
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.mobile.mediaQueryRetina. url,
					$fVSCtrl.dimensions.mobile.width, $fVSCtrl.dimensions.mobile.height, $fVSCtrl.jellyParameters, 2) + "']";
				// Tablet
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.tablet.mediaQuery. url,
					$fVSCtrl.dimensions.tablet.width, $fVSCtrl.dimensions.tablet.height, $fVSCtrl.jellyParameters, 1) + "']";
				// Tablet Retina
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.tablet.mediaQueryRetina. url,
					$fVSCtrl.dimensions.tablet.width, $fVSCtrl.dimensions.tablet.height, $fVSCtrl.jellyParameters, 2) + "']";
				// Desktop
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.desktop.mediaQuery. url,
					$fVSCtrl.dimensions.desktop.width, $fVSCtrl.dimensions.desktop.height, $fVSCtrl.jellyParameters, 1) + "']";
				// Desktop Retina
				$fVSCtrl.allJellyUrls += "['" + $fVSCtrl.generateSingleIrisUrl($fVSCtrl.dimensions.desktop.mediaQueryRetina. url,
					$fVSCtrl.dimensions.desktop.width, $fVSCtrl.dimensions.desktop.height, $fVSCtrl.jellyParameters, 2) + "']";
			}
			if($fVSCtrl.jellyUrl !== "") {
				$fVSCtrl.checkIfJellyIsLoading();
				$rootScope.$broadcast('form-vehicle-selector:jelly-info-updated', {
					mfgCode: $fVSCtrl.objJellyTrimGroupUrl.trims[0].mfgCode,
					jellyUrl: $fVSCtrl.jellyUrl
				});
			}
        };

        $fVSCtrl.generateSingleIrisUrl = (mediaQuery, baseUrl, width, height, parameters, pixelDensity) => {
            let url = mediaQuery + "', '" + baseUrl;
            url += "&width=" + (width * pixelDensity);
            if(height !== undefined) {
                url += "&height="+ (height * pixelDensity);
            }
            if(parameters !== undefined) {
                url += "&" + $fVSCtrl.jellyParameters;
            }
            url += "&bkgnd=" + $fVSCtrl.background;
            return url;
        };

        $fVSCtrl.loaded = (selector) => {
            $(selector).hide();
        };

        $fVSCtrl.loading = (selector) => {
            $(selector).show();
        };

        $fVSCtrl.checkIfJellyIsLoading = () => {
            $('.C_FVS-jellyImage').on('load', $fVSCtrl.loaded('.C_FVS-form-vehicleCard-jellyContainer-loader'));
        };


        /**
         * when the user selects a brand (first pull down)
         */
        $fVSCtrl.selectBrand = () => {
            try {
                $fVSCtrl.hideNameplateSelector = true;
                $fVSCtrl.hideTrimGroupSelector = true;
                $fVSCtrl.clearNameplates($fVSCtrl.currentBrandCode);
                $fVSCtrl.changeVisibleSelectField($fVSCtrl.nameplateSelectField);
                $fVSCtrl.hideNameplateSelector = false;
                $fVSCtrl.loaded('.C_FVS-loader');
            } catch (error) {
                console.error("error selecting brand", error);
            }
        };

        /**
         * when the user selects a nameplate (second pull-down)
         */
        $fVSCtrl.selectNameplate = () => {
            try {
                $fVSCtrl.hideTrimGroupSelector = true;
                $fVSCtrl.clearTrimGroup(); // clear the third pull-down
                $fVSCtrl.seperateYearFromNamePlate();
                $fVSCtrl.showTrimGroups($fVSCtrl.nameplateCode, $fVSCtrl.year);
                $fVSCtrl.validateTrimGroupPackage();
                $fVSCtrl.loaded('.C_FVS-loader');
            } catch (error) {
                console.error("error selecting nameplate", error);
            }
        };

        /**
         * when the user selects a trim group (third pull-down)
         */
        $fVSCtrl.selectTrimGroup = () => {
            if (!$fVSCtrl.skipJellyDisplay) {
                try {
                    $fVSCtrl.getTrimGroupNameAndModelYearId(
                        $fVSCtrl.trimGroupCode);
                    $fVSCtrl.changeVisibleSelectField(
                        $fVSCtrl.vehicleTileField);
                    $fVSCtrl.getVehicleJelly($fVSCtrl.brandCode,
                        $fVSCtrl.nameplateCode, $fVSCtrl.year,
                        $fVSCtrl.modelYearId);
                    $fVSCtrl.loaded(
                        '.C_FVS-form-vehicleCard-jellyContainer-loader');
                } catch (error) {
                    console.error("error selecting trim group", error);
                }
            } else {
                try {
                    $fVSCtrl.getTrimGroupNameAndModelYearId($fVSCtrl.trimGroupCode);
                } catch (error) {
                    console.error("error selecting trim group", error);
                }
            }
        };

        $fVSCtrl.changeVisibleSelectField = (fieldToShow) => {
            //  Some vehicles don't have any trims, so we skip to the vehicle tile
            if(fieldToShow === $fVSCtrl.trimGroupSelectField) {
                if($fVSCtrl.trimGroups.length) {
                    $fVSCtrl.visibleSelectField = fieldToShow;
                } else {
                    $fVSCtrl.visibleSelectField = $fVSCtrl.vehicleTileField;
                }
            } else {
                $fVSCtrl.visibleSelectField = fieldToShow;
            }
            if($fVSCtrl.visibleSelectField === $fVSCtrl.vehicleTileField) {
                $fVSCtrl.carouselSlideWidth = $('.C_FVS-form-step-singleDealer-container li').outerWidth();
                $fVSCtrl.carouselSlideLeftValue = $fVSCtrl.carouselSlideWidth * (-1);
                $('.C_FVS-form-step-singleDealer-container li:first').before($('.C_FVS-form-step-singleDealer-container li:last'));
                $('.C_FVS-form-step-singleDealer-container').css({'left' : $fVSCtrl.carouselSlideLeftValue});


                $rootScope.$broadcast('form-vehicle-selector:vehicle-changed', [true]);
            } else {
                $rootScope.$broadcast('form-vehicle-selector:vehicle-changed', [false]);
            }
        };

        $fVSCtrl.clearTrimGroup = () => {
            if($fVSCtrl.trimGroup !== "") {
                $fVSCtrl.trimGroup = ``;
                $fVSCtrl.trimGroupCode = "";
            }
        };

        $fVSCtrl.clearNameplates = (brandValue) => {
            if($fVSCtrl.combinedNamePlatesYears.length) {
                $fVSCtrl.combinedNamePlatesYears = [];
                $fVSCtrl.trimGroups = [];
                $fVSCtrl.clearTrimGroup();
                $fVSCtrl.nameplateYear = ``;
                $fVSCtrl.nameplate = ``;
                $fVSCtrl.year = "";
                $fVSCtrl.setCurrentBrandCode(brandValue);
                $fVSCtrl.getBrandName(brandValue);
                $fVSCtrl.showNamePlates(brandValue);
            }
        };

        $fVSCtrl.clearJellyUrl = () => {
            $fVSCtrl.jellyUrl = "";
        };

        $fVSCtrl.showTrimGroups = (nameplateValue, nameplateYearValue) => {

          let trimGroups = $fVSCtrl.combinedNamePlatesYears.filter((element) => element.nameplateCode === nameplateValue && element.nameplateYear === nameplateYearValue);
          $fVSCtrl.modelYearId = trimGroups[0].modelYearId;

            let trimPricesUrl =
                `/api/buildandprice/trims/prices`+
                `?provinceCode=${$fVSCtrl.location.province}`+
                `&nameplate=${$fVSCtrl.nameplateCode}`+
                `&modelYear=${$fVSCtrl.year}`+
                `&modelYearId=${$fVSCtrl.modelYearId}`+
                `&brand=${$fVSCtrl.brandCode}`;

            let trimSelectorUrl =
                `/api/buildandprice/${$fVSCtrl.language}`+
                `/brand/${$fVSCtrl.brandCode}`+
                `/nameplate/${$fVSCtrl.nameplateCode}`+
                `/year/${$fVSCtrl.year}`+
                `/modelyear/${$fVSCtrl.modelYearId}/trims`;

            $fVSCtrl.trimGroups = trimGroups[0].trimGroups;
            if(trimGroups[0].trimGroups.length) {
                $http.get(trimPricesUrl).then((result) => {
                    $fVSCtrl.pricingObject = result.data;

                    // wait for the trim prices before we call the trim selector
                    $http.get(trimSelectorUrl).then((result) => {
                        $fVSCtrl.packageObject = result.data.trimGroups;
                        // now that we have everything we need, actually show the trim groups
                        $fVSCtrl.hideTrimGroupSelector = false;
                        $fVSCtrl.changeVisibleSelectField($fVSCtrl.trimGroupSelectField);
                    });
                });
            }
        };

        $fVSCtrl.getBrandCode = (brandValue) => {
            let arrBrand = $fVSCtrl.brands.filter((element) => element.brandName === brandValue);
            arrBrand = $fVSCtrl.getCurrentBrand(arrBrand);
            $fVSCtrl.brandCode = arrBrand[0].brandCode;
        };

        $fVSCtrl.getBrandName = (brandValue) => {
            let arrBrand = $fVSCtrl.brands.filter((element) => element.brandCode === brandValue);
            arrBrand = $fVSCtrl.getCurrentBrand(arrBrand);
            if(brandValue === 'ramtruck') {
                $fVSCtrl.brand = arrBrand[0].brandName.toUpperCase();
            } else {
                $fVSCtrl.brand = arrBrand[0].brandName;
            }
        };

        $fVSCtrl.getCurrentBrand = (arrBrand) => {
            if (arrBrand.length != 1) {
                arrBrand = $fVSCtrl.currentBrand;
            }
            return arrBrand;
        };

        $fVSCtrl.isSubBrand = (codeValue) => {
            return $fVSCtrl.brands.filter((brand) => brand.subBrandCode != "" && brand.subBrandCode === codeValue).length > 0;
        };

        $fVSCtrl.setCurrentBrandCode = (currentBrandCodeValue) => {
            $fVSCtrl.currentSubBrandCode = $fVSCtrl.isSubBrand(currentBrandCodeValue) ? currentBrandCodeValue : "";
            let arrBrand = $fVSCtrl.brands.filter((element) => element.brandCode === currentBrandCodeValue);

            if(arrBrand.length === 0) {
                arrBrand = $fVSCtrl.brands.filter((element) => element.subBrandCode === $fVSCtrl.currentSubBrandCode);
            }

            if(arrBrand.length > 1) {
                arrBrand = arrBrand.filter((element) => element.subBrandCode === $fVSCtrl.currentSubBrandCode);
            }

            $fVSCtrl.brandCode = arrBrand[0].brandCode;
            $fVSCtrl.currentBrand = arrBrand;
        };

		$fVSCtrl.isOneClassPrice = () => {
			return $fVSCtrl.trimGroupData.type === "TrimGroupNode";
		}

        $fVSCtrl.getTrimGroupNameAndModelYearId = (trimGroupValue) => {
            $fVSCtrl.trimGroupData = $fVSCtrl.trimGroups.filter((element) => element.code === trimGroupValue)[0];
            $fVSCtrl.trimGroup = $fVSCtrl.isOneClassPrice() ? $fVSCtrl.trimGroupData.name : $fVSCtrl.trimGroupData.packageDesc;
            $fVSCtrl.getCalculatorParameters();
        };

		$fVSCtrl.getSelectedPackageData = () => {
            /**
             * Some vehicle doesn't have trimgroup (empty value). If this is the case,
             * we show the package name
             */
			if ($fVSCtrl.trimGroupData.type === "TrimGroupNode") {
				return $fVSCtrl.packageObject.filter((element) => element.code === $fVSCtrl.trimGroupData.trimDesc)[0];
			}

			return $fVSCtrl.packageObject[0];
		}


		$fVSCtrl.getAcodeForCalculator = () => {

			// A trimgroup could have more than 1 acode, we want to take the lowest one
			let arrPricing = $fVSCtrl.pricingObject.length > 1 ? $fVSCtrl.pricingObject.filter((element) => element.trimGroup === $fVSCtrl.trimGroupData.trimDesc).sort(function (a, b) {
				return Number(a.startingAtPrice) - Number(b.startingAtPrice);
			})[0]: $fVSCtrl.pricingObject[0];


			if (!arrPricing) {
				throw new Error("could not determine price for trim group " + $fVSCtrl.trimGroupData.trimDesc + " (invalid trim group in data?)");
			}

			return !$fVSCtrl.isDaaMode ? arrPricing.acode :  $fVSCtrl.acode;
		}

        $fVSCtrl.getCalculatorParameters = () => {
			$fVSCtrl.acode = $fVSCtrl.getAcodeForCalculator();

            $fVSCtrl.packageAndOptionsCode = null;

			if($fVSCtrl.trimGroupData.type !== "PackageNode") {
				try {
					let packageObj = $fVSCtrl.getPackageObject($fVSCtrl.packageObject, $fVSCtrl.acode);
					$fVSCtrl.packageAndOptionsCode = packageObj.code;
				} catch (error) {
					console.log(error);
				}
			} else {
				$fVSCtrl.packageAndOptionsCode = $fVSCtrl.trimGroupCode;
			}

            let configApiUrl = `/api/buildandprice/config?acode=${$fVSCtrl.acode}&language=${$fVSCtrl.language}&provinceCode=${$fVSCtrl.location.province}&modelYearId=${$fVSCtrl.modelYearId}&nameplateCode=${$fVSCtrl.nameplateCode}&year=${$fVSCtrl.year}&brand=${$fVSCtrl.brandCode}`
            if ($fVSCtrl.packageAndOptionsCode) {
                configApiUrl += `&packageCode=${$fVSCtrl.packageAndOptionsCode}`;
            }

            storeAffiliateId();
            if (isAffiliateMode()) {
                configApiUrl += getAffiliateIdUrlParam();
            }

            $http.get(configApiUrl).then((result) => {
                const configData = result.data;
                $fVSCtrl.additionalPricing = configData.pricing;

                for (const packageData in configData) {
                    if (configData.hasOwnProperty(packageData) && typeof configData[packageData] === 'object') {
                        if (configData[packageData].hasOwnProperty('categories') && configData[packageData].categories.length) {
                            configData[packageData].categories.forEach(category => {
                                if (category.hasOwnProperty('options') && category.options.length) {
                                    $fVSCtrl.packageAndOptionsCodeArray.push(category.options[0].code);
                                }
                            });
                        }
                    }
                }
                $rootScope.$broadcast('form-vehicle-selector:trim-selected', {
                    trim: $fVSCtrl.trimGroup,
                    modelYearId: $fVSCtrl.modelYearId,
                    acode: $fVSCtrl.acode,
                    packageAndOptionsCode: $fVSCtrl.packageAndOptionsCodeArray,
                    basePrice: Number(configData.pricing.net.msrp),
                    otherFees: Number($fVSCtrl.additionalPricing.acTax.msrp) + Number($fVSCtrl.additionalPricing.freight.msrp) + Number($fVSCtrl.additionalPricing.greenLevy.msrp),
                    incentives: $fVSCtrl.additionalPricing.incentives,
                    pricing: $fVSCtrl.additionalPricing
                });
            })
        };

        $fVSCtrl.getNameplateNameFromCode = (nameplateValue) => {
            $fVSCtrl.nameplate = $fVSCtrl.combinedNamePlatesYears.filter((element) => element.nameplateCode === nameplateValue)[0].nameplateName;
        };

        $fVSCtrl.seperateYearFromNamePlate = () => {
            const filteredCombinedNamePlatesYears = $fVSCtrl.combinedNamePlatesYears.find(element => element.fullNamePlate === $fVSCtrl.nameplateYear);
            $fVSCtrl.year = filteredCombinedNamePlatesYears.nameplateYear;
            $fVSCtrl.nameplate = filteredCombinedNamePlatesYears.nameplateName;
            $fVSCtrl.nameplateCode = filteredCombinedNamePlatesYears.nameplateCode;
            $rootScope.$broadcast('form-vehicle-selector:nameplate-selected', {
                nameplate: $fVSCtrl.nameplateCode,
                year: $fVSCtrl.year,
                nameplateName: $fVSCtrl.nameplate,
                brandName: $fVSCtrl.brand,
                brandCode: $fVSCtrl.brandCode
            });
        };

        $fVSCtrl.isStandardNameplateTitle = () => {
            let originalNameplateLength = $fVSCtrl.nameplateYear.length;
            let shortNameplateLength = $fVSCtrl.nameplate.length + $fVSCtrl.year.length + $fVSCtrl.brand.length + 2;// Plus 2 to account for the spaces
            return shortNameplateLength === originalNameplateLength;
        };

        $fVSCtrl.checkIfNameplateIncludesYearOrBrand = (nameplate, yearOrBrand) => {
            return nameplate.includes(yearOrBrand);
        };

        $fVSCtrl.isSniStandardNameplateTitle = () => {
            return $fVSCtrl.nameplate.includes($fVSCtrl.brand);
        };

        $fVSCtrl.formatNameplateName = (fullNameplateNameAndYear) => {
            return fullNameplateNameAndYear.replace('<sub>®</sub>', '®')
                                           .replace('<sup>&reg;</sup>', '®')
                                           .replace('<sup>MD</sup>', 'ᴹᴰ');
        };

        /**
         * Get the cheapest package for the acode in the trim selector data
         */
        $fVSCtrl.getPackageObject = (packagesData, acode) => {
            let result = null;

            // start by locating the trim group in the trim selector
            let trimPackages = null;
            let trimGroupPackages = packagesData.find(function(trimGroup) {
                // find the trim group that contains the current acode
                trimPackages = trimGroup.trims.find((element) => element.acode === acode);
                if (trimPackages) {
                    return true;
                } else {
                    return false;
                }
            });
            if (!trimPackages) {
                throw new Error("trim selector doesn't contain any trim group with acode " + acode);
            }

            // get the cheapest package for that trim
            let packages = trimPackages.packages;
            if (packages) {
                if(packages.length > 1) {
                    result = packages.sort(function (a, b) {
                        return Number(a.upgradePrice) - Number(b.upgradePrice);
                    })[0];
                } else { // there's only one package, use it
                    result = packages[0];
                }
            } else {
                throw new Error("trim selector doesn't have any package for acode " + acode);
            }

            return result;
        };

        $fVSCtrl.generateAltTag = (pModelYear, pBrandName, pNamepateName,
            pTrimGroup) => {
            let brandName = window.FCA_SITES_CONFIG.name;
            let altText = '';

            altText = pModelYear + ' ' + pBrandName + ' ' + pNamepateName + ' '
                + pTrimGroup;
            if (window.FCA_SITES_CONFIG.language == 'fr') {
                altText = pBrandName + ' ' + pNamepateName + ' ' + pModelYear
                    + ' ' + pTrimGroup;
            }
            return altText;
        };

        $fVSCtrl.focusOnSelect = () => {
            $timeout(() => {
                const whatWeFocusOn = document.querySelectorAll('.C_FVS-form-formGroup select')[0];

                if (whatWeFocusOn) {
                    whatWeFocusOn.focus();
                }
            });
        };

        $fVSCtrl.changeVehicleChoice = (fieldToShow) => {
            $fVSCtrl.changeVisibleSelectField(fieldToShow);
            $fVSCtrl.clearJellyUrl();
            $fVSCtrl.focusOnSelect();
        }

        $fVSCtrl.formatTrimGroup = (trimGroup) => {
            return trimGroup.replace('4xe', '<span class="text-transform-none">4xe</span>')
        }

        $fVSCtrl.formatNameplate = (name) => {
            return name.replace('4xe', '<span class="text-transform-none">4xe</span>')
        }

        $fVSCtrl.validateTrimGroupPackage = () => {
            $fVSCtrl.hasTrimGroupPackage = !!$fVSCtrl.trimGroups.find(element => element.packageDesc);
        }
    }
})();
