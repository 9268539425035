(function () {
	angular
		.module('fca.commonFilters')
		.directive('fcaFleetDocumentsFilters', fcaFleetDocumentsFilters);

	function fcaFleetDocumentsFilters() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: fleetDocumentsFiltersController
		};

		function fleetDocumentsFiltersController($scope, $element) {
			'ngInject';
			const $ctrl = this;
			const ALL_CATEGORIES = 'all-categories';
			const ALL_BRANDS = 'all-brands';

			$ctrl.filteredCategory = ALL_CATEGORIES;
			$ctrl.filteredBrand = ALL_BRANDS;
			$ctrl.availableBrands = [];

			const initializeDropdownListeners = () => {
				const categoryDropdown = $element[0].querySelector('.doc-filter-category ncl-dropdown');
				const brandDropdown = $element[0].querySelector('.doc-filter-brand ncl-dropdown');

				categoryDropdown.addEventListener('ncl:dropdown.change', handleCategoryChange);
                brandDropdown.addEventListener('ncl:dropdown.change', handleBrandChange);
			}

			const handleCategoryChange = ({ detail }) => {
				if (detail.value !== $ctrl.filteredCategory) {
					$scope.$apply(() => {
						$ctrl.filteredCategory = detail.value;
						$ctrl.filterByCategory();
					});
				}
			}

			const handleBrandChange = ({ detail }) => {
				if (detail.value !== $ctrl.filteredBrand) {
					$scope.$apply(() => {
						$ctrl.filteredBrand = detail.value;
						$ctrl.filterByBrand();
					});
				}
			}

			const resetBrandsFilters = () => {
				$ctrl.filteredBrand = ALL_BRANDS;
				$element[0].querySelectorAll('.doc-list-item').forEach((item) => {
					item.style.display =  'flex';
				});
				setTimeout(() => {
					$element[0].querySelector('.doc-filter-brand ncl-dropdown-item[selected]').removeAttribute('selected');
					$element[0].querySelector('.doc-filter-brand ncl-dropdown-item:first-child').setAttribute('selected', true);
				}, 0);
			}

			const updateCategoryDisplay = () => {
				$element[0].querySelectorAll('.doc-list-category').forEach((item) => {
					if ($ctrl.filteredBrand === ALL_BRANDS) {
						item.style.display = item.id === $ctrl.filteredCategory || $ctrl.filteredCategory === ALL_CATEGORIES ? 'block' : 'none';
					} else {
						item.style.display = Array.from(item.querySelectorAll('.doc-list-item')).filter((child) => child.style.display === 'flex').length ? 'block' : 'none';
					}
				});
			}

			const updateAvailableBrands = () => {
				$ctrl.availableBrands = Array
					.from($element[0].querySelectorAll('.doc-list-category'))
					.filter((item) => item.style.display === 'block')
					.map((item) => Array.from(item.querySelectorAll('.doc-list-item')))
					.flat()
					.map((item) => item.getAttribute('data-brand-document'))
					.filter((item) => item !== '')
					.filter((item, index, self) => self.indexOf(item) === index);
			}

			const updateBrandDisplay = () => {
				$element[0].querySelectorAll('.doc-list-item').forEach((item) => {
					item.style.display = item.getAttribute('data-brand-document') === $ctrl.filteredBrand || $ctrl.filteredBrand === ALL_BRANDS ? 'flex' : 'none';
				});
			}

			$ctrl.isBrandAvailable = (brand) => $ctrl.availableBrands.includes(brand) || $ctrl.filteredCategory === ALL_CATEGORIES;

			$ctrl.hasBrandsAvailable = () => $ctrl.availableBrands.length > 0 || $ctrl.filteredCategory === ALL_CATEGORIES;

			$ctrl.filterByCategory = () => {
				resetBrandsFilters();
				updateCategoryDisplay();
				updateAvailableBrands();
			}

			$ctrl.filterByBrand = () => {
				updateBrandDisplay();
				updateCategoryDisplay();
			}

			$ctrl.$onInit = () => {
				initializeDropdownListeners();
			}
		}
	}
})();
