(function() {
	angular
		.module('fca.core.main')
		.directive('fcaConfirmLeave', fcaConfirmLeave);

	function fcaConfirmLeave() {
		return {
			restrict: 'A',
			scope: {
				fcaConfirmLeaveText: '@'
			},
			link: FcaConfirmLeaveLink
		};

		function FcaConfirmLeaveLink(scope, element, attrs) {
			let isHTMLLink = element.is('a');
			let externalLink = isHTMLLink ? element.attr('href') : element.find('a').attr('href');
			let message = scope.fcaConfirmLeaveText;
			let confirmed = false;

			element.on('click', function(e) {
				e.preventDefault();
				confirmed = confirm(message);

				if (confirmed) {
					let target = isHTMLLink ? element.attr('target') : element.find('a').attr('target');

					if (target != null) {
						window.open(externalLink, target);
					} else {
						window.location = externalLink;
					}
				}
			});
		}
	}
})();
